import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Spinner,
  CardTitle,
} from "reactstrap"

//actions
import { getDashboardData } from "store/dashboard/actions"
import MetaTag from "components/Common/Meta-tag"

const Dashboard = () => {
  const dispatch = useDispatch()

  const { dashboardData, loading, classificationInputs } = useSelector(
    state => ({
      dashboardData: state.Dashboard.dashboardData,
      loading: state.Dashboard.loading,
      classificationInputs:
        state.Settings.settings?.setup?.classificationInputs,
    })
  )

  useEffect(() => {
    dispatch(getDashboardData())
  }, [dispatch])

  const [categories, setCategories] = useState(false)

  {
    const reports = [
      {
        title: "Orders",
        iconClass: "bx-copy-alt",
        description: `${dashboardData?.ordersCount}`,
        sbadges: "New Orders",
        sbadgesRate: `${dashboardData?.newOrdersCount}`,
      },
      {
        title: "Products",
        iconClass: "bx-purchase-tag-alt",
        description: `${dashboardData?.productsCount}`,
        wbadges: "Out of stock",
        wbadgesRate: `${dashboardData?.outOfStockProductsCount}`,
      },
      // {
      //   title: "Products",
      //   iconClass: "bx-copy-alt",
      //   description: `${dashboardData?.productsCount}`
      // },
      // {
      //   title: "Shop Products",
      //   iconClass: "bx-copy-alt",
      //   description: `${dashboardData?.shopProductsCount}`
      // },
    ]

    const classification = [
      {
        value: "category",
        title: "Categories",
        iconClass: "bx-archive-in",
        description: `${dashboardData?.categoriesCount}`,
      },
      {
        value: "subcategory",
        title: "Sub Categories",
        iconClass: "bx-archive-in",
        description: `${dashboardData?.subCategoriesCount}`,
      },
      {
        value: "brand",
        title: "Brands",
        iconClass: "bx-archive-in",
        description: `${dashboardData?.brandsCount}`,
      },
      {
        value: "agegroup",
        title: "Age Groups",
        iconClass: "bx-archive-in",
        description: `${dashboardData?.ageGroupsCount}`,
      },
      {
        value: "gendertype",
        title: "Gender Types",
        iconClass: "bx-archive-in",
        description: `${dashboardData?.genderTypesCount}`,
      },
    ]

    const ids = classificationInputs?.map(i => i.name)

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTag title="Dashboard" />
          {!categories ? (
            <Container fluid>
              <Col className="row">{/* <DashedLine /> */}</Col>
              <CardTitle>Dashboard</CardTitle>

              <Col xl="12">
                <Row>
                  {/* Reports Render */}
                  {loading ? (
                    <Spinner color="secondary" className="d-block m-auto" />
                  ) : (
                    <>
                      {reports?.map((report, key) => (
                        <Col md="4" key={"_col_" + key}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p className="text-muted fw-medium">
                                    {report.title}
                                  </p>
                                  <h4 className="mb-0">{report.description}</h4>
                                </Media>

                                {/* badges with success */}
                                <Media body className="text-center">
                                  <div className="flex-grow-1">
                                    <p className="text-white fw-medium rounded-pill bg-success badge">
                                      {report.sbadges}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.sbadgesRate}
                                    </h4>
                                  </div>
                                </Media>

                                {/* badges with warning */}
                                <Media body className="text-center">
                                  <div className="flex-grow-1">
                                    <p className="text-white fw-medium rounded-pill bg-warning badge">
                                      {report.wbadges}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.wbadgesRate}
                                    </h4>
                                  </div>
                                </Media>

                                {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div> */}
                              </Media>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}

                      {classification?.map((report, key) => (
                        <Col md="4" key={"_col_" + key}>
                          {ids?.includes(report?.value) ? (
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <Media>
                                  <Media body>
                                    <p className="text-muted fw-medium">
                                      {report.title}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.description}
                                    </h4>
                                  </Media>

                                  {/* badges with success */}
                                  <Media body className="text-center">
                                    <div className="flex-grow-1">
                                      <p className="text-white fw-medium rounded-pill bg-success badge">
                                        {report.sbadges}
                                      </p>
                                      <h4 className="mb-0">
                                        {report.sbadgesRate}
                                      </h4>
                                    </div>
                                  </Media>

                                  {/* badges with warning */}
                                  <Media body className="text-center">
                                    <div className="flex-grow-1">
                                      <p className="text-white fw-medium rounded-pill bg-warning badge">
                                        {report.wbadges}
                                      </p>
                                      <h4 className="mb-0">
                                        {report.wbadgesRate}
                                      </h4>
                                    </div>
                                  </Media>
                                </Media>
                              </CardBody>
                            </Card>
                          ) : (
                            ""
                          )}
                        </Col>
                      ))}
                    </>
                  )}
                </Row>
              </Col>
            </Container>
          ) : (
            <div></div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard
