import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAIL,
  GET_DELETED_PRODUCTS,
  GET_DELETED_PRODUCTS_SUCCESS,
  GET_DELETED_PRODUCTS_FAIL,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  CHANGE_PRODUCT_STATUS,
  CHANGE_PRODUCT_STATUS_SUCCESS,
  CHANGE_PRODUCT_STATUS_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  HARD_DELETE_PRODUCT,
  HARD_DELETE_PRODUCT_SUCCESS,
  HARD_DELETE_PRODUCT_FAIL,
  RESTORE_PRODUCT,
  RESTORE_PRODUCT_SUCCESS,
  RESTORE_PRODUCT_FAIL,
  CREATE_DUPLICATE_PRODUCT,
  CREATE_DUPLICATE_PRODUCT_SUCCESS,
  CREATE_DUPLICATE_PRODUCT_FAIL,
  UNDO_REFRASH,
} from "./actionTypes"

const INIT_STATE = {
  products: {
    products: [],
    total: "",
    page: "",
  },
  productDetails: {},
  productVarients: {},
  error: {},
  loading: false,
  createProductError: null,
  needRefrash: false,
}

const Products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
    case GET_ALL_PRODUCTS:
    case GET_DELETED_PRODUCTS:
    case GET_PRODUCT_DETAILS:
    case CREATE_PRODUCT:
    case UPDATE_PRODUCT:
    case CHANGE_PRODUCT_STATUS:
    case DELETE_PRODUCT:
    case HARD_DELETE_PRODUCT:
    case RESTORE_PRODUCT:
    case CREATE_DUPLICATE_PRODUCT:
      return {
        ...state,
        loading: true,
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        total: action.payload.total,
        loading: false,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //all
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        total: action.payload.total,
        loading: false,
      }

    case GET_ALL_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DELETED_PRODUCTS_SUCCESS:
      return {
        ...state,
        deletedProducts: action.payload.data,
        loading: false,
      }

    case GET_DELETED_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetails: action.payload,
        productVarients: action.payload,
        loading: false,
      }

    case GET_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [action.payload, ...state.products],
        createProductError: null,
        loading: false,
      }

    case CREATE_PRODUCT_FAIL:
      return {
        ...state,
        createProductError: action.payload,
        loading: false,
      }

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products].map(product =>
          product._id.toString() === action.payload._id.toString()
            ? { product, ...action.payload }
            : product
        ),
        loading: false,
      }

    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CHANGE_PRODUCT_STATUS_SUCCESS:
      return {
        ...state,
        products: [...state.products].map(product =>
          product._id.toString() === action.payload._id.toString()
            ? { product, ...action.payload }
            : product
        ),
        loading: false,
      }

    case CHANGE_PRODUCT_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case HARD_DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    case HARD_DELETE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case RESTORE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    case RESTORE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_DUPLICATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [action.payload, ...state.products],
        createProductError: null,
        loading: false,
        needRefrash: true,
      }

    case CREATE_DUPLICATE_PRODUCT_FAIL:
      return {
        ...state,
        createProductError: action.payload,
        loading: false,
        needRefrash: false,
      }

    case UNDO_REFRASH:
      return {
        ...state,
        needRefrash: false,
      }

    default:
      return state
  }
}

export default Products
