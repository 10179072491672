import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  GET_COLLECTIONS,
  GET_COLLECTION_DETAILS,
  UPDATE_COLLECTION,
} from "./actionTypes"
import {
  createCollectionFail,
  createCollectionSuccess,
  deleteCollectionFail,
  deleteCollectionSuccess,
  getCollectionsDtailsFail,
  getCollectionsDtailsSuccess,
  getCollectionsFail,
  getCollectionsSuccess,
  updateCollectionFail,
  updateCollectionSuccess,
} from "./actions"

import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function collectionsApi() {
  return get("/collection/admin/all")
}

const getCollectionsDetailsAPi = collectionId => {
  return get(`/collection/admin/${collectionId}`)
}

function createCollectionsApi({ collection }) {
  return post("/collection/admin/new", collection)
}

function updateCollectionsApi({ collection, collectionId }) {
  return ApiPut(`/collection/admin/${collectionId}`, collection)
}

function deleteCollectionsApi(collectionId) {
  return del(`/collection/admin/${collectionId}`)
}

function* fetchCollections() {
  try {
    const response = yield call(collectionsApi)
    yield put(getCollectionsSuccess(response))
  } catch (error) {
    yield put(getCollectionsFail(error))
  }
}

function* fetchCollectionDetails({ payload: zestonProductId }) {
  try {
    const response = yield call(getCollectionsDetailsAPi, zestonProductId)
    yield put(getCollectionsDtailsSuccess(response))
  } catch (error) {
    yield put(getCollectionsDtailsFail(error))
  }
}

function* onCreateCollection({ payload }) {
  try {
    const response = yield call(createCollectionsApi, payload)
    yield put(createCollectionSuccess(response))
    Notification({
      type: "success",
      message: "Collection Created Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createCollectionFail(error))
  }
}

function* onUpdateColletion({ payload }) {
  try {
    const response = yield call(updateCollectionsApi, payload)
    yield put(updateCollectionSuccess(response))
    Notification({
      type: "success",
      message: "Collection Updated Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateCollectionFail(error))
  }
}

function* onDeleteColletion({ CollectionId, history }) {
  try {
    const response = yield call(deleteCollectionsApi, CollectionId)
    yield put(deleteCollectionSuccess(response))
    Notification({
      type: "error",
      message: "Collection Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteCollectionFail(error))
  }
}

function* collectionsSaga() {
  yield takeEvery(GET_COLLECTIONS, fetchCollections)
  yield takeEvery(GET_COLLECTION_DETAILS, fetchCollectionDetails)
  yield takeEvery(CREATE_COLLECTION, onCreateCollection)
  yield takeEvery(UPDATE_COLLECTION, onUpdateColletion)
  yield takeEvery(DELETE_COLLECTION, onDeleteColletion)
}

export default collectionsSaga
