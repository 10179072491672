import {
  GET_BRANDS,
  GET_BRANDS_FAIL,
  GET_BRANDS_SUCCESS,
  GET_BRAND_DETAILS,
  GET_BRAND_DETAILS_SUCCESS,
  GET_BRAND_DETAILS_FAIL,
  CREATE_BRAND,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAIL,
  UPDATE_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
} from "./actionTypes"

//all brands
export const getBrands = () => ({
  type: GET_BRANDS,
})

export const getBrandsSuccess = brands => ({
  type: GET_BRANDS_SUCCESS,
  payload: brands,
})

export const getBrandsFail = error => ({
  type: GET_BRANDS_FAIL,
  payload: error,
})

export const getBrandDetails = brandId => ({
  type: GET_BRAND_DETAILS,
  payload: brandId,
})

export const getBrandDetailsSuccess = brandDetails => ({
  type: GET_BRAND_DETAILS_SUCCESS,
  payload: brandDetails,
})

export const getBrandDetailsFail = error => ({
  type: GET_BRAND_DETAILS_FAIL,
  payload: error,
})

export const createBrand = (brand, history) => ({
  type: CREATE_BRAND,
  payload: {brand, history},
})

export const createBrandSuccess = brand => ({
  type: CREATE_BRAND_SUCCESS,
  payload: brand
})

export const createBrandFail = error => ({
  type: CREATE_BRAND_FAIL,
  payload: error,
})

export const updateBrand = (brand, brandId, history) => ({
  type: UPDATE_BRAND,
  payload: {brand, brandId, history},
})

export const updateBrandSuccess = brand => ({
  type: UPDATE_BRAND_SUCCESS,
  payload: brand,
})

export const updateBrandFail = error => ({
  type: UPDATE_BRAND_FAIL,
  payload: error,
})

export const deleteBrand = (brandId, history) => ({
  type: DELETE_BRAND, 
  brandId, history
})

export const deleteBrandSuccess = brand => ({
  type: DELETE_BRAND_SUCCESS,
  payload: brand,
})

export const deleteBrandFail = error => ({
  type: DELETE_BRAND_FAIL,
  payload: error,
})