import { call, put, takeEvery } from "redux-saga/effects"

// Settings Redux States
import { GET_NOTIFICATION, GET_SETTINGS } from "./actionTypes"
import {
  getNotificationSuccess,
  getNotificationFail
  
} from "./actions"

//Include Both Helper File with needed methods
import { post, get, ApiPut, del } from "../../helpers/api_helper"
import {Notification} from "../../components/Common/Notification"

function notificationsApi({page}) {
  return get(`/notification/all?page=${page ? page : 1}&limit=10`)
}


function* fetchNotifications({payload}) {
  try {
    const response = yield call(notificationsApi, payload)
    yield put(getNotificationSuccess(response))
  } catch (error) {
    yield put(getNotificationFail(error))
  }
}

function* notificationsSaga() {
  yield takeEvery(GET_NOTIFICATION, fetchNotifications)
}

export default notificationsSaga
