export const GET_AGEGROUPS = "GET_AGEGROUPS"
export const GET_AGEGROUPS_SUCCESS = "GET_AGEGROUPS_SUCCESS"
export const GET_AGEGROUPS_FAIL = "GET_AGEGROUPS_FAIL"

export const GET_AGEGROUP_DETAILS = "GET_AGEGROUP_DETAILS"
export const GET_AGEGROUP_DETAILS_SUCCESS = "GET_AGEGROUP_DETAILS_SUCCESS"
export const GET_AGEGROUP_DETAILS_FAIL = "GET_AGEGROUP_DETAILS_FAIL"

export const CREATE_AGEGROUP = "CREATE_AGEGROUP"
export const CREATE_AGEGROUP_SUCCESS = "CREATE_AGEGROUP_SUCCESS"
export const CREATE_AGEGROUP_FAIL = "CREATE_AGEGROUP_FAIL"

export const UPDATE_AGEGROUP = "UPDATE_AGEGROUP"
export const UPDATE_AGEGROUP_SUCCESS = "UPDATE_AGEGROUP_SUCCESS"
export const UPDATE_AGEGROUP_FAIL = "UPDATE_AGEGROUP_FAIL"

export const DELETE_AGEGROUP = "DELETE_AGEGROUP"
export const DELETE_AGEGROUP_SUCCESS = "DELETE_AGEGROUP_SUCCESS"
export const DELETE_AGEGROUP_FAIL = "DELETE_AGEGROUP_FAIL"
