/* PROJECTS */
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

export const GET_CATEGORY_DETAILS = "GET_CATEGORY_DETAILS"
export const GET_CATEGORY_DETAILS_SUCCESS = "GET_CATEGORY_DETAILS_SUCCESS"
export const GET_CATEGORY_DETAILS_FAIL = "GET_CATEGORY_DETAILS_FAIL"

/**
 * add user
 */
 export const CREATE_CATEGORY = "CREATE_CATEGORY"
 export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS"
 export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
 export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
 export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_CATEGORY = "DELETE_CATEGORY"
 export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
 export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL"
