import { useHistory } from "react-router"
import { Col, Container, Row } from "reactstrap"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { createCoupon } from "store/actions"

import { map } from "lodash"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

const CreateCoupon = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => ({
    loading: state.Coupon.loading,
    error: state.Coupon.error,
  }))

  const [couponDuration, setCouponDuration] = useState()

  const CouponDuration = [
    {
      id: 1,
      title: "DURATION",
      value: "Duration",
    },
    {
      id: 2,
      title: "FULL TIME",
      value: "Full time",
    },
    {
      id: 3,
      title: "START TIME",
      value: "Start time",
    },
  ]

  function handleValidSubmit(values) {
    const createCouponData = {
      name: values.name,
      couponCode: values.couponCode,
      couponType: values.couponType,
      durationType: couponDuration,
      validFrom: values.validFrom,
      validTo: values.validTo,
      discountPercentage: values.discountPercentage,
      discountValue: values.discountValue,
      maxValue: values.maxValue,
      totalUserLimit: values.totalUserLimit,
      perUserLimit: values.perUserLimit,
    }

    console.log("data :", createCouponData)
    dispatch(createCoupon(createCouponData, history))
  }

  const isMult = LanguageSwitch()?.isMult

  const couponTypeOption = [
    "Custom Value",
    "Custom Percentage",
    "New User",
    "First Order",
    "Free Shipping",
    "Whitelist Users",
    "Product Set",
    "Category Set",
  ]

  // console.log("title =>")
  console.log("data :", error.message)

  return (
    <>
      <MetaTag title="Create Groups" />

      <div className="page-content">
        <Breadcrumbs title="Groups" breadcrumbItem="Create Group" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4"
                            style={{ color: "#1e81b0" }}
                          >
                            Name
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="name"
                              required
                              label=""
                              type="text"
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4 "
                            style={{ color: "#1e81b0" }}
                          >
                            Coupon Code
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="couponCode"
                              required
                              label=""
                              type="text"
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4"
                            style={{ color: "#1e81b0" }}
                          >
                            Coupon Type
                          </CardTitle>
                          <AvField type="select" name="couponType" required>
                            <option>Select a Coupon Type</option>
                            {couponTypeOption?.map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="12">
                  <Card>
                    <Row>
                      <Col xl="6" md="12">
                        <CardBody>
                          <CardTitle
                            className="h4 mb-4"
                            style={{ color: "#1e81b0" }}
                          >
                            Duration
                          </CardTitle>
                          <Row>
                            <div
                              className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              {map(CouponDuration, (item, key) => (
                                <div key={key} className="me-3">
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="durationType"
                                    id={item.id}
                                    autoComplete="off"
                                    value={item.value}
                                    onChange={e =>
                                      setCouponDuration(e.target.value)
                                    }
                                    defaultChecked={item.value === "Duration"}
                                  />
                                  <label
                                    className="btn btn-outline-info"
                                    htmlFor={item.id}
                                  >
                                    {item.title}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Row>
                        </CardBody>
                      </Col>
                      {couponDuration === "Full time" ? (
                        <></>
                      ) : (
                        <Col xl="6" md="12">
                          <CardBody>
                            <Row>
                              <Col md="6" sm="12">
                                <CardTitle
                                  className="h4 mb-4"
                                  style={{ color: "#1e81b0" }}
                                >
                                  Valid From
                                </CardTitle>
                                <div className="mb-3">
                                  <AvField
                                    name="validFrom"
                                    label=""
                                    type="date"
                                  />
                                </div>
                              </Col>

                              {couponDuration === "Start time" ? (
                                <></>
                              ) : (
                                <Col md="6" sm="12">
                                  <CardTitle
                                    className="h4 mb-4 "
                                    style={{ color: "#1e81b0" }}
                                  >
                                    Valid To
                                  </CardTitle>
                                  <div className="mb-3">
                                    <AvField
                                      name="validTo"
                                      label=""
                                      type="date"
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </CardBody>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>

                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4"
                            style={{ color: "#1e81b0" }}
                          >
                            Discount Percentage
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="discountPercentage"
                              label=""
                              type="number"
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4 "
                            style={{ color: "#1e81b0" }}
                          >
                            Discount Value
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="discountValue"
                              label=""
                              type="number"
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4"
                            style={{ color: "#1e81b0" }}
                          >
                            Max Value
                          </CardTitle>
                          <div className="mb-3">
                            <AvField name="maxValue" label="" type="number" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="4" lg="6" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4"
                            style={{ color: "#1e81b0" }}
                          >
                            {/* Maximum no.of People per Coupon */}
                            Total user limit
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="totalUserLimit"
                              label=""
                              type="number"
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="6" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4 "
                            style={{ color: "#1e81b0" }}
                          >
                            {/* Maximum Coupon for a Person */}
                            Per user limit
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="perUserLimit"
                              label=""
                              type="number"
                            />
                          </div>
                        </Col>

                        {/* <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4"
                            style={{ color: "#1e81b0" }}
                          >
                            Deduction Count
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="deductionCount"
                              label=""
                              type="number"
                            />
                          </div>
                        </Col> */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Coupon
                            </button>
                          </div>
                          {error?.message && (
                            <p
                              className="mt-2 mb-0 pb-0"
                              style={{ textAlign: "end", color: "#ff0000a3" }}
                            >
                              {" "}
                              <spann>!</spann> {error?.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateCoupon
