import { map, range } from "lodash"
import { Button, Spinner } from "reactstrap"
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Link, useHistory, useLocation } from "react-router-dom"

import MyPagination from "../MyPagination"

//actions
import { deleteGenderType, deleteSubCategory, getSettings } from "store/actions"
import { getAllClassifications } from "store/classifications/actions"
import { deleteAgeGroup, deleteBrand, deleteCategory } from "store/actions"

import "../../assets/scss/datatables.scss"

import { LanguageSwitch } from "common/LanguageSwitch"
import DeleteModal from "components/Common/DeleteModal"
import noImage from "../../assets/images/zeston/noimage3.png"

const Classifications = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const [searchText, setSearchText] = useState("")
  const [limit, setLimit] = useState("10")
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState()

  const [isOpen, setIsOpen] = useState({
    stauts: false,
    id: "",
    loading: false,
  })

  const query = location?.search.slice(1)

  const { classifications, loading, classificationInputs } = useSelector(
    state => ({
      classifications: state.Classifications.classifications,
      loading: state.Classifications.loading,
      classificationInputs:
        state.Settings.settings?.setup?.classificationInputs,
    })
  )

  const total = classifications?.length
  const isMult = LanguageSwitch()?.isMult

  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })

    if (sort === "category") return dispatch(deleteCategory(isOpen?.id))
    if (sort === "subcategory") return dispatch(deleteSubCategory(isOpen?.id))
    if (sort === "gendertype") return dispatch(deleteGenderType(isOpen?.id))
    if (sort === "agegroup") return dispatch(deleteAgeGroup(isOpen?.id))
    if (sort === "brand") return dispatch(deleteBrand(isOpen?.id))

    return dispatch(deleteCategory(isOpen?.id))
  }

  useEffect(() => {
    dispatch(
      getAllClassifications(
        pageSend(),
        query ? query : sort ?? "category",
        limit,
        searchText
      )
    )
    if (isOpen.loading) {
      dispatch(
        getAllClassifications(
          pageSend(),
          query ? query : sort ?? "category",
          limit,
          searchText
        )
      )
    }
  }, [dispatch, page, searchText, isOpen.loading])

  useEffect(() => {
    if (query) {
      setSort(query)
    } else {
      setSort("category")
    }
  }, [])

  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])

  //pagination
  const totalPages = Math.ceil(total / limit)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const columns = [
    {
      dataField: "primaryLang.name",
      text: "Primary Name",
      sort: true,
    },
    {
      dataField: "secondaryLang.name",
      text: "Secondary Name",
      sort: true,
    },
    {
      dataField: "logoImage",
      text: "Logo Image",
      sort: true,
    },
    {
      dataField: "bannerImage",
      text: "Banner Image",
    },
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  columns[0].text = isMult ? "Primary Name" : "Name"

  const handleLinks = item => {
    if (sort === "category") return `/category/update/${item?._id}`
    if (sort === "subcategory") return `/subcategory/update/${item?._id}`
    if (sort === "gendertype") return `/genderType/update/${item?._id}`
    if (sort === "agegroup") return `/agegroup/update/${item?._id}`
    if (sort === "brand") return `/brand/update/${item?._id}`
    return `/category/${item?._id}`
  }

  const allClassificationsData = map(classifications, (item, index) => ({
    ...item,
    logoImage: (
      <>
        <img
          src={
            item?.logoImages && item?.logoImages[0]
              ? item?.logoImages[0]?.url
              : noImage
          }
          width="50px"
        />
      </>
    ),
    bannerImage: (
      <>
        <img
          src={
            item?.bannerImages && item?.bannerImages[0]
              ? item?.bannerImages[0]?.url
              : noImage
          }
          width="50px"
        />
      </>
    ),
    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={handleLinks(item)}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bxs-pencil me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        </Link>

        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => handleDelete(item?._id)}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "20px" }}
          />
        </Button>
      </div>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const listChange = sortValue => {
    history.push({
      pathname: "/classifications",
      search: `${sortValue}`,
    })
    setSort(sortValue)
    dispatch(getAllClassifications(pageSend(), sortValue, limit))
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              {/* <h4 className="card-title mb-3">Orders</h4> */}

              <Col xl={6} className="mb-3">
                <div
                  className="btn-group mt-2 mt-xl-0"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  {map(classificationInputs, (item, key) => (
                    <Fragment key={key}>
                      {item?.name === "category" && (
                        <>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                            autoComplete="off"
                            checked={
                              query === "category" ||
                              (sort === "category" && true)
                            }
                            onChange={() => listChange("category")}
                          />
                          <label
                            className="btn btn-primary"
                            htmlFor="btnradio1"
                          >
                            Category
                          </label>
                        </>
                      )}

                      {item?.name === "subcategory" && (
                        <>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio2"
                            autoComplete="off"
                            checked={query === "subcategory" && true}
                            onChange={() => listChange("subcategory")}
                          />
                          <label
                            className="btn btn-primary"
                            htmlFor="btnradio2"
                          >
                            Sub-category
                          </label>
                        </>
                      )}
                      {item?.name === "brand" && (
                        <>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio3"
                            autoComplete="off"
                            checked={query === "brand" && true}
                            onChange={() => listChange("brand")}
                          />
                          <label
                            className="btn btn-primary"
                            htmlFor="btnradio3"
                          >
                            Brand
                          </label>
                        </>
                      )}

                      {item?.name === "gendertype" && (
                        <>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio4"
                            autoComplete="off"
                            checked={query === "gendertype" && true}
                            onChange={() => listChange("gendertype")}
                          />
                          <label
                            className="btn btn-primary"
                            htmlFor="btnradio4"
                          >
                            Gender Type
                          </label>
                        </>
                      )}

                      {item?.name === "agegroup" && (
                        <>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio5"
                            checked={query === "agegroup" && true}
                            autoComplete="off"
                            onChange={() => listChange("agegroup")}
                          />
                          <label
                            className="btn btn-primary"
                            htmlFor="btnradio5"
                          >
                            Age Group
                          </label>
                        </>
                      )}
                    </Fragment>
                  ))}
                </div>
              </Col>

              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={
                    isMult
                      ? columns
                      : columns?.filter(
                          i => i.dataField !== "secondaryLang.name"
                        )
                  }
                  data={allClassificationsData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              {/* <SearchBar {...toolkitProps.searchProps} /> */}
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e => setSearchText(e.target.value)}
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="8">
                          {classificationInputs?.length < 1 ? (
                            <div className="text-sm-end">
                              <Link to="/settings">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add Classification
                                </Button>
                              </Link>
                            </div>
                          ) : (
                            <div className="text-sm-end">
                              {(query == "category" || sort == "category") && (
                                <Link to="/category/create/new">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Category
                                  </Button>
                                </Link>
                              )}
                              {query == "subcategory" && (
                                <Link to="/subcategory/create/new">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Sub Category
                                  </Button>
                                </Link>
                              )}
                              {query == "brand" && (
                                <Link to="/brand/create/new">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Brand
                                  </Button>
                                </Link>
                              )}
                              {query == "gendertype" && (
                                <Link to="/genderType/create/new">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Gender Type
                                  </Button>
                                </Link>
                              )}
                              {query == "agegroup" && (
                                <Link to="/agegroup/create/new">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Age Group
                                  </Button>
                                </Link>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {classifications?.length >= 1 ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p className="my-4 mx-3 ">No Classifications !</p>
                          )}
                          <MyPagination
                            pages={pages}
                            clcickedPage={page}
                            onNunClick={item => setPage(item)}
                            onNextClick={() => setPage(page + 1)}
                            onPrevClick={() => setPage(page - 1)}
                            apiPage={pageSend}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Classifications
