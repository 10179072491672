import {
  GET_GENDER_TYPES,
  GET_GENDER_TYPES_FAIL,
  GET_GENDER_TYPES_SUCCESS,
  GET_GENDER_TYPE_DETAILS,
  GET_GENDER_TYPE_DETAILS_SUCCESS,
  GET_GENDER_TYPE_DETAILS_FAIL,
  CREATE_GENDER_TYPE,
  CREATE_GENDER_TYPE_SUCCESS,
  CREATE_GENDER_TYPE_FAIL,
  UPDATE_GENDER_TYPE,
  UPDATE_GENDER_TYPE_SUCCESS,
  UPDATE_GENDER_TYPE_FAIL,
  DELETE_GENDER_TYPE,
  DELETE_GENDER_TYPE_SUCCESS,
  DELETE_GENDER_TYPE_FAIL,
} from "./actionTypes"

//all genderTypes
export const getGenderTypes = () => ({
  type: GET_GENDER_TYPES,
})

export const getGenderTypesSuccess = genderTypes => ({
  type: GET_GENDER_TYPES_SUCCESS,
  payload: genderTypes,
})

export const getGenderTypesFail = error => ({
  type: GET_GENDER_TYPES_FAIL,
  payload: error,
})

export const getGenderTypeDetails = genderTypeId => ({
  type: GET_GENDER_TYPE_DETAILS,
  payload: genderTypeId,
})

export const getGenderTypeDetailsSuccess = genderTypeDetails => ({
  type: GET_GENDER_TYPE_DETAILS_SUCCESS,
  payload: genderTypeDetails,
})

export const getGenderTypeDetailsFail = error => ({
  type: GET_GENDER_TYPE_DETAILS_FAIL,
  payload: error,
})

export const createGenderType = (genderType, history) => ({
  type: CREATE_GENDER_TYPE,
  payload: {genderType, history},
})

export const createGenderTypeSuccess = genderType => ({
  type: CREATE_GENDER_TYPE_SUCCESS,
  payload: genderType
})

export const createGenderTypeFail = error => ({
  type: CREATE_GENDER_TYPE_FAIL,
  payload: error,
})

export const updateGenderType = (genderType, genderTypeId, history) => ({
  type: UPDATE_GENDER_TYPE,
  payload: {genderType, genderTypeId, history},
})

export const updateGenderTypeSuccess = genderType => ({
  type: UPDATE_GENDER_TYPE_SUCCESS,
  payload: genderType,
})

export const updateGenderTypeFail = error => ({
  type: UPDATE_GENDER_TYPE_FAIL,
  payload: error,
})

export const deleteGenderType = (genderTypeId, history) => ({
  type: DELETE_GENDER_TYPE, 
  genderTypeId, history
})

export const deleteGenderTypeSuccess = genderType => ({
  type: DELETE_GENDER_TYPE_SUCCESS,
  payload: genderType,
})

export const deleteGenderTypeFail = error => ({
  type: DELETE_GENDER_TYPE_FAIL,
  payload: error,
})