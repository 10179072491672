import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORY_DETAILS,
  GET_CATEGORY_DETAILS_SUCCESS,
  GET_CATEGORY_DETAILS_FAIL,
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
} from "./actionTypes"

//all categories
export const getCategories = () => ({
  type: GET_CATEGORIES,
})

//all categories
export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const getCategoryDetails = categoryId => ({
  type: GET_CATEGORY_DETAILS,
  payload: categoryId,
})

export const getCategoryDetailsSuccess = categoryDetails => ({
  type: GET_CATEGORY_DETAILS_SUCCESS,
  payload: categoryDetails,
})

export const getCategoryDetailsFail = error => ({
  type: GET_CATEGORY_DETAILS_FAIL,
  payload: error,
})

export const createCategory = (category, history) => ({
  type: CREATE_CATEGORY,
  payload: {category, history},
})

export const createCategorySuccess = category => ({
  type: CREATE_CATEGORY_SUCCESS,
  payload: category
})

export const createCategoryFail = error => ({
  type: CREATE_CATEGORY_FAIL,
  payload: error,
})

export const updateCategory = (category, categoryId, history) => ({
  type: UPDATE_CATEGORY,
  payload: {category, categoryId, history},
})

export const updateCategorySuccess = category => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: category,
})

export const updateCategoryFail = error => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
})

export const deleteCategory = (categoryId, history) => ({
  type: DELETE_CATEGORY, 
  categoryId, history
})

export const deleteCategorySuccess = category => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: category,
})

export const deleteCategoryFail = error => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
})


