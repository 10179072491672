import {
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_SUCCESS,

} from "./actionTypes"

const INIT_STATE = {
  notifications: {},
  error: {},
}

const Notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      }

    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    
    default:
      return state
  }
}

export default Notifications
