export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES"
export const GET_SUBCATEGORIES_SUCCESS = "GET_SUBCATEGORIES_SUCCESS"
export const GET_SUBCATEGORIES_FAIL = "GET_SUBCATEGORIES_FAIL"

export const GET_SUBCATEGORY_DETAILS = "GET_SUBCATEGORY_DETAILS"
export const GET_SUBCATEGORY_DETAILS_SUCCESS = "GET_SUBCATEGORY_DETAILS_SUCCESS"
export const GET_SUBCATEGORY_DETAILS_FAIL = "GET_SUBCATEGORY_DETAILS_FAIL"

export const CREATE_SUBCATEGORY = "CREATE_SUBCATEGORY"
export const CREATE_SUBCATEGORY_SUCCESS = "CREATE_SUBCATEGORY_SUCCESS"
export const CREATE_SUBCATEGORY_FAIL = "CREATE_SUBCATEGORY_FAIL"

export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY"
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS"
export const UPDATE_SUBCATEGORY_FAIL = "UPDATE_SUBCATEGORY_FAIL"

export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY"
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS"
export const DELETE_SUBCATEGORY_FAIL = "DELETE_SUBCATEGORY_FAIL"
