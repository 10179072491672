import Select from "react-select"
import { useHistory } from "react-router"
import { TabContent, TabPane } from "reactstrap"
import { Col, Container, Row } from "reactstrap"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardSubtitle, CardTitle, FormGroup } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { createGroup, getCollections } from "store/actions"

import ImageInput from "components/Common/MyInput"
import LanguageTab from "components/Common/LanguageTab"
import { map } from "lodash"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

const CrateGroups = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading, Collections } = useSelector(state => ({
    loading: state.Group.loading,
    Collections: state.Collection.collections,
  }))

  const [bannerImages, setBannerImages] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  const [searchText, setSearchText] = useState("")
  const [selectedCollection, setSelectedCollection] = useState(
    "Search a Collection"
  )
  const [selectedCollectionId, setSelectedCollectionId] = useState("")

  const LayoutData = [
    {
      id: 1,
      title: "HIGHLIGHT",
      value: "highlight",
    },
    {
      id: 2,
      title: "LIST VERTICAL",
      value: "list_vertical",
    },
    {
      id: 3,
      title: "LIST HORIZONTAL",
      value: "list_horizontal",
    },
    {
      id: 4,
      title: " CAROUSEL",
      value: "carousel",
    },
    {
      id: 5,
      title: "LIST VERTICAL SMALL",
      value: "list_vertical_sm",
    },
  ]
  const [layout, setLayout] = useState("")

  useEffect(() => {
    dispatch(getCollections(1, "", searchText))
  }, [dispatch, searchText])

  function handleValidSubmit(values) {
    const createGroupData = {
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      priority: parseInt(values.priority),
      layout: layout,
      collectionId: selectedCollectionId,
      images: bannerImages,
    }

    dispatch(createGroup(createGroupData, history))
  }

  const onChangeBannerImageHandler = e => {
    const files = Array.from(e.target.files)

    setBannerImagesPreview([])
    setBannerImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setBannerImagesPreview(oldArray => [
            ...oldArray,
            { url: reader.result, id: oldArray.length + 1 },
          ])
          setBannerImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const onDeleteImg = id => {
    setBannerImagesPreview(bannerImagesPreview?.filter(item => item.id !== id))
  }

  //collection dropdown
  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  function handlerCollectionFinalValue(event) {
    setSelectedCollection(event.label)
    setSelectedCollectionId(event.value)
  }

  const collectionOptions = [
    {
      options:
        Collections?.length &&
        Collections?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.title,
          value: result._id,
        })),
    },
  ]

  const isMult = LanguageSwitch()?.isMult

  return (
    <>
      <MetaTag title="Create Groups" />

      <div className="page-content">
        <Breadcrumbs title="Groups" breadcrumbItem="Create Group" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4"
                            style={{ color: "#1e81b0" }}
                          >
                            Priority
                          </CardTitle>
                          <div className="mb-3">
                            <AvField name="priority" label="" type="number" />
                          </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4 "
                            style={{ color: "#1e81b0" }}
                          >
                            Collections
                          </CardTitle>
                          <FormGroup className="mb-3">
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleEnters}
                                value={selectedCollection}
                                placeholder={selectedCollection}
                                onChange={handlerCollectionFinalValue}
                                options={collectionOptions}
                                classNamePrefix="select2-selection"
                                isLoading={true}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"
                        style={{ color: "#1e81b0" }}
                      >
                        Select Layout
                      </CardTitle>
                      <Row>
                        <div
                          className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {map(LayoutData, (item, key) => (
                            <div key={key} className="me-3">
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={item.id}
                                autoComplete="off"
                                value={item.value}
                                onChange={e => setLayout(e.target.value)}
                              />
                              <label
                                className="btn btn-outline-info"
                                htmlFor={item.id}
                              >
                                {item.title}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Col className="col-12 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <AvField
                                name="primaryLang.title"
                                className=""
                                label="Title"
                                type="text"
                                errorMessage="Invalid product name"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <AvField
                                name="primaryLang.description"
                                label="Description"
                                type="textarea"
                                errorMessage="Invalid description"
                                rows="7"
                              />
                            </div>
                          </Col>
                        </TabPane>

                        <TabPane tabId="2">
                          <Col className="col-12 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <div className="d-flex justify-content-between mb-2">
                                <CardSubtitle>Title</CardSubtitle>
                                <CardSubtitle>اسم</CardSubtitle>
                              </div>
                              <AvField
                                style={{ textAlign: "right" }}
                                name="secondaryLang.title"
                                label=""
                                type="text"
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <div className="d-flex justify-content-between mb-2">
                                <CardSubtitle>Description</CardSubtitle>
                                <CardSubtitle>وصف</CardSubtitle>
                              </div>
                              <AvField
                                style={{ textAlign: "right" }}
                                name="secondaryLang.description"
                                label=""
                                type="textarea"
                                rows="7"
                              />
                            </div>
                          </Col>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="form-group px-3">
                        <CardTitle className="mb-4">Banner images :</CardTitle>

                        <div className="custom-file d-flex justify-content-start align-items-center">
                          <input
                            type="file"
                            name="banner_images"
                            className="custom-file-input"
                            id="banner_input"
                            onChange={onChangeBannerImageHandler}
                            multiple
                            hidden
                          />
                          <ImageInput htmlFor="banner_input" />

                          <div className="d-flex justify-content-end flex-wrap align-items-center">
                            {bannerImagesPreview?.map((img, index) => (
                              <div
                                style={{
                                  position: "relative",
                                }}
                                className="mb-2 mx-5"
                                key={index}
                              >
                                <img
                                  src={img?.url}
                                  alt="Images Preview"
                                  className="mr-2"
                                  style={{ marginRight: ".5rem" }}
                                  width="75"
                                  height="72"
                                />
                                <i
                                  className="bx bx-x-circle"
                                  style={{
                                    color: "red",
                                    fontSize: "1.2rem",
                                    position: "absolute",
                                    top: "2px",
                                    right: "12px",
                                    background: "#e9e9ea",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => onDeleteImg(img?.id)}
                                ></i>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Group
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CrateGroups
