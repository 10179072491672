import React from 'react'
import { Container } from "reactstrap"
import { MetaTags } from 'react-meta-tags'

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DatatableTables from "./DatatableTables"

function Shop() {
    return (
        <>
            <MetaTags>
                <title>All Shops | Zeston </title>
            </MetaTags>
            <div className="page-content">
                <Breadcrumbs title="Dashboard" breadcrumbItem="All Shops" />
                <Container fluid>
                    <div className="container-fluid">
                        <DatatableTables />
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Shop
