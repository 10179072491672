import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  CREATE_COUPON,
  DELETE_COUPON,
  GET_COUPONS,
  GET_COUPON_DETAILS,
  UPDATE_COUPON,
} from "./actionTypes"
import {
  createCouponFail,
  createCouponSuccess,
  deleteCouponFail,
  deleteCouponSuccess,
  getCouponsDtailsFail,
  getCouponsDtailsSuccess,
  getCouponsFail,
  getCouponsSuccess,
  updateCouponFail,
  updateCouponSuccess,
} from "./actions"

import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function couponsApi() {
  return get("/coupon/admin/all")
}

const getCouponsDetailsAPi = couponId => {
  return get(`/coupon/admin/single/${couponId}`)
}

function createCouponsApi({ coupon }) {
  return post("/coupon/admin/new", coupon)
}

function updateCouponsApi({ coupon, couponId }) {
  return ApiPut(`/coupon/admin/single/${couponId}`, coupon)
}

function deleteCouponsApi(couponId) {
  return del(`/coupon/admin/single/${couponId}`)
}

function* fetchCoupons() {
  try {
    const response = yield call(couponsApi)
    yield put(getCouponsSuccess(response))
  } catch (error) {
    yield put(getCouponsFail(error))
  }
}

function* fetchCouponDetails({ payload: couponId }) {
  try {
    const response = yield call(getCouponsDetailsAPi, couponId)
    yield put(getCouponsDtailsSuccess(response))
  } catch (error) {
    yield put(getCouponsDtailsFail(error))
  }
}

function* onCreateCoupon({ payload }) {
  try {
    const response = yield call(createCouponsApi, payload)
    yield put(createCouponSuccess(response))
    Notification({
      type: "success",
      message: "Coupon Created Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createCouponFail(error.response.data))
  }
}

function* onUpdateCoupons({ payload }) {
  try {
    const response = yield call(updateCouponsApi, payload)
    yield put(updateCouponSuccess(response))
    Notification({
      type: "success",
      message: "Coupon Updated Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateCouponFail(error))
  }
}

function* onDeleteCoupons({ couponId }) {
  try {
    const response = yield call(deleteCouponsApi, couponId)
    yield put(deleteCouponSuccess(response))
    Notification({
      type: "success",
      message: "Coupon Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteCouponFail(error))
  }
}

function* couponsSaga() {
  yield takeEvery(GET_COUPONS, fetchCoupons)
  yield takeEvery(GET_COUPON_DETAILS, fetchCouponDetails)
  yield takeEvery(CREATE_COUPON, onCreateCoupon)
  yield takeEvery(UPDATE_COUPON, onUpdateCoupons)
  yield takeEvery(DELETE_COUPON, onDeleteCoupons)
}

export default couponsSaga
