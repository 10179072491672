import MetaTag from "components/Common/Meta-tag"
import React from "react"

import ProductDetail from "./Details"

function ProductDetails() {
  return (
    <>
      <MetaTag title={"Product"} />

      <ProductDetail />
    </>
  )
}

export default ProductDetails
