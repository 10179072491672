import React, { Fragment, useState } from "react"
import { EditorState, ContentState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import PropTypes from "prop-types"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import htmlToDraft from "html-to-draftjs"
import draftToMarkdown from "draftjs-to-markdown"
import { convertToRaw } from "draft-js"

const FormEditors = ({ content, setContent }) => {
  const blocksFromHtml = htmlToDraft(content)
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  )

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  )

  const handleEditorChange = state => {
    setEditorState(state)
    convertContentToHTML()
  }

  const convertContentToHTML = () => {
    setContent(
      editorState &&
        draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
    )
  }

  //   useEffect(() => {
  //     // setEditorState(() => EditorState.createWithContent(convertToRaw(content)))
  //   }, [])

  console.log(content)

  return (
    <Fragment>
      <div className="db__column">
        {/* <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          value={content ? content : ""}
        /> */}
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={handleEditorChange}
          //   onChange={e => console.log(e)}
          value={content ? content : ""}
        />
      </div>
    </Fragment>
  )
}

export default FormEditors

FormEditors.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func,
}
