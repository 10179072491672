import {
  GET_SUBCATEGORIES,
  GET_SUBCATEGORIES_FAIL,
  GET_SUBCATEGORIES_SUCCESS,
  GET_SUBCATEGORY_DETAILS,
  GET_SUBCATEGORY_DETAILS_SUCCESS,
  GET_SUBCATEGORY_DETAILS_FAIL,
  CREATE_SUBCATEGORY_SUCCESS,
  CREATE_SUBCATEGORY_FAIL,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY,
  UPDATE_SUBCATEGORY_FAIL,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAIL,
  CREATE_SUBCATEGORY,
} from "./actionTypes"

const INIT_STATE = {
  subCategories: [],
  subCategoryDetails: {},
  error: {},
  loading: false,
}

const SubCategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBCATEGORIES:
    case GET_SUBCATEGORY_DETAILS:
    case UPDATE_SUBCATEGORY:
    case CREATE_SUBCATEGORY:
      return {
        ...state,
        loading: true,
      }
    case GET_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        subCategories: action.payload,
        loading: false,
      }

    case GET_SUBCATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SUBCATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        subCategoryDetails: action.payload,
        loading: false,
      }

    case GET_SUBCATEGORY_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subCategories: [...state.subCategories, action.payload],
        loading: false,
      }

    case CREATE_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subCategories: [...state.subCategories].map(subCategory =>
          subCategory._id.toString() === action.payload._id.toString()
            ? { subCategory, ...action.payload }
            : subCategory
        ),
        loading: false,
      }

    case UPDATE_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // case DELETE_SUBCATEGORY_SUCCESS:
    //   return {
    //     ...state,
    //     subCategories: state.subCategories.filter(
    //       subCategory =>
    //         subCategory._id.toString() !== action.payload._id.toString()
    //     ),
    //     loading: false,
    //   }

    case DELETE_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default SubCategory
