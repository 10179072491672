import {
  GET_GENDER_TYPES,
  GET_GENDER_TYPES_FAIL,
  GET_GENDER_TYPES_SUCCESS,
  GET_GENDER_TYPE_DETAILS,
  GET_GENDER_TYPE_DETAILS_SUCCESS,
  GET_GENDER_TYPE_DETAILS_FAIL,
  CREATE_GENDER_TYPE_SUCCESS,
  CREATE_GENDER_TYPE_FAIL,
  UPDATE_GENDER_TYPE_SUCCESS,
  UPDATE_GENDER_TYPE,
  UPDATE_GENDER_TYPE_FAIL,
  DELETE_GENDER_TYPE_SUCCESS,
  DELETE_GENDER_TYPE_FAIL,
  CREATE_GENDER_TYPE,
} from "./actionTypes"

const INIT_STATE = {
  genderTypes: [],
  genderTypeDetails: {},
  error: {},
  loading: false,
}

const GenderType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GENDER_TYPES:
    case GET_GENDER_TYPE_DETAILS:
    case UPDATE_GENDER_TYPE:
    case CREATE_GENDER_TYPE:
      return {
        ...state,
        loading: true,
      }
    case GET_GENDER_TYPES_SUCCESS:
      return {
        ...state,
        genderTypes: action.payload,
        loading: false,
      }

    case GET_GENDER_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_GENDER_TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        genderTypeDetails: action.payload,
        loading: false,
      }

    case GET_GENDER_TYPE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_GENDER_TYPE_SUCCESS:
      return {
        ...state,
        genderTypes: [...state.genderTypes, action.payload],
        loading: false,
      }

    case CREATE_GENDER_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_GENDER_TYPE_SUCCESS:
      return {
        ...state,
        genderTypes: [...state.genderTypes].map(genderType =>
          genderType._id.toString() === action.payload._id.toString()
            ? { genderType, ...action.payload }
            : genderType
        ),
        loading: false,
      }

    case UPDATE_GENDER_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // case DELETE_GENDER_TYPE_SUCCESS:
    // return {
    //   ...state,
    //   genderTypes: state.genderTypes.filter(
    //     genderType =>
    //       genderType._id.toString() !== action.payload._id.toString()
    //   ),
    //   loading: false,
    // }

    case DELETE_GENDER_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default GenderType
