import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router"
import { Button, Card, CardBody, Col } from "reactstrap"
import { Container, Label, Row, Media, Badge } from "reactstrap"
import { isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//actions
import {
  deleteUser,
  getUserDetails,
  getUserCarts,
  getUserCartDetails,
  getUserFavouriteDetails,
} from "store/users/actions"
import { getUserOrderDetails } from "store/orders/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import CartDatatable from "./CartDatatable"
import OrderDatatable from "./OrderDatatable"
import FavDatatable from "./FavDatatable"
import { CurrencySwitch } from "common/Currency"

const UserDetail = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { userDetails } = useSelector(state => ({
    userDetails: state.Users.userDetails,
  }))

  const { userCartDetails } = useSelector(state => ({
    userCartDetails: state.Users.userCartDetails,
  }))

  const { userFavouriteDetails } = useSelector(state => ({
    userFavouriteDetails: state.Users.userFavouriteDetails,
  }))

  const { userOrderDetails } = useSelector(state => ({
    userOrderDetails: state.Orders.userOrderDetails.orders,
  }))

  const handleDelete = () => {
    dispatch(deleteUser(userDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getUserCarts())
  }, [dispatch])

  useEffect(() => {
    dispatch(getUserDetails(params.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getUserCartDetails(params.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getUserFavouriteDetails(params.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getUserOrderDetails(params.id))
  }, [dispatch])

  const currency = CurrencySwitch()

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Details | Shop</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="User" breadcrumbItem="User Details" />
          {!isEmpty(userDetails) && (
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="9">
                          <Media>
                            <Media className="align-self-center" body>
                              <div className="text-muted">
                                <h5 className="mb-1">{userDetails?.name}</h5>
                                <Badge
                                  className={
                                    userDetails.isGuest
                                      ? "font-size-12 badge-soft-primary"
                                      : userDetails.role == "admin"
                                      ? "font-size-12 badge-soft-secondary"
                                      : "font-size-12 badge-soft-success"
                                  }
                                  color=""
                                  pill
                                >
                                  {userDetails?.isGuest
                                    ? "guest"
                                    : userDetails?.role}
                                </Badge>
                                <p className="mb-0 mt-2">
                                  {userDetails?.email}
                                </p>
                                {/* <p className="mb-0">
                                  UserID: {userDetails?._id}
                                </p> */}
                              </div>
                            </Media>
                          </Media>
                        </Col>

                        {/* <Col className="d-none d-lg-block col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                          <div className="clearfix mt-4 mt-lg-0">
                            <Link to={`/user/update/${userDetails?._id}`}>
                              <Button
                                type="button"
                                color="success"
                                className="btn  mt-2 me-1"
                              >
                                <i className="bx bx-edit me-2" /> Update
                              </Button>
                            </Link>
                            <Button
                              type="button"
                              color="warning"
                              className="ms-1 btn mt-2"
                              onClick={() => {
                                handleDelete(userDetails?._id)
                              }}
                            >
                              <i className="bx bx-user-x me-2" />
                              Block
                            </Button>
                          </div>
                        </Col> */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {userCartDetails?.cartItems?.length >= 1 && (
                <Row>
                  <Col>
                    <Card className="col-12">
                      <CardBody>
                        <Label>
                          Cart Items: {userCartDetails?.cartItems?.length}
                        </Label>
                        <CartDatatable />

                        <Row>
                          <Col>
                            <hr />
                            <Col className="text-end mb-2">
                              Items Total : {currency}{" "}
                              {userCartDetails?.itemsPrice}
                            </Col>
                            <Col className="text-end mb-2">
                              Shipping Total : {currency}{" "}
                              {userCartDetails?.shippingPrice}
                            </Col>
                            <Col className="text-end mb-2">
                              VAT Total (15%) : {currency}{" "}
                              {userCartDetails?.taxValue}
                            </Col>
                            <Col className="text-end mb-2">
                              Gross Total : {currency}{" "}
                              {userCartDetails?.totalPrice}
                            </Col>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
              {userFavouriteDetails?.length >= 1 && (
                <Row>
                  <Col>
                    <Card className="col-12">
                      <CardBody>
                        <Label>
                          Favourite Items: {userFavouriteDetails?.data?.length}
                        </Label>
                        <FavDatatable />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}{" "}
              {userOrderDetails?.length >= 1 && (
                <Row>
                  <Col>
                    <Card className="col-12">
                      <CardBody>
                        <Label>Orders: {userOrderDetails?.length}</Label>
                        <OrderDatatable />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

UserDetail.propTypes = {
  user: PropTypes.object,
  match: PropTypes.any,
  onGetUserDetail: PropTypes.func,
}

export default UserDetail
