import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
} from "reactstrap"
import Switch from "react-switch";
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from '../MyPagination'

//actions
import { getShops } from "store/shops/actions"

import "../../assets/scss/datatables.scss"

const Shops = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  //shops
  const { shops, total, loading } = useSelector(state => ({
    shops: state.Shops.shops?.shops,
    total: state.Shops.shops?.total,
    loading: state.Shops.loading,
  }))

  //pagination 
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)


  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => { 
    dispatch(getShops(pageSend()))
  }, [dispatch, page, searchText])

  

  const columns = [

    {
      dataField: "shopNo",
      text: "Shop Number",
      sort: true,
    },
    {
      dataField: "dealerNo",
      text: "Dealer Number",
      sort: true,
    },
    {
      dataField: "shopName",
      text: "Shop Name",
      sort: true,
    },
    {
      dataField: "dealerDetails.name",
      text: "Dealer Name",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const shopData = map(shops, item => ({
    ...item,
    
    activeData:(
      <>
        {/* <Switch  onChange={() => handleSwitchChange(item?._id)} checked={item?.isActive} /> */}
        <div
          className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {handleSwitchChange(item?._id)}} checked={item?.isActive}
          />
          <label
            className="form-check-label"
            htmlFor="customSwitchsizemd"
          >
           {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action:(
      <div>
        <Link to={`/shop/${item?._id}`} className="btn-light btn-sm">
          View Details
        </Link>
      </div>
    )

  }))

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: shops?.length,
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const { SearchBar } = Search;

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const handleSwitchChange = (e,id) => {
    // TODO: API needed for status change
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={shopData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      
                      <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to="shop/create/new">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Shop
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner
                        color="secondary"
                        className="d-block m-auto"
                      />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                // keyField={"id"}
                                key={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={(item) => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          apiPage={pageSend}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Shops
