import {
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
  
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
} from "./actionTypes"

//all settings
export const getSettings = () => ({
  type: GET_SETTINGS,
})

//all settings
export const getSettingsSuccess = settings => ({
  type: GET_SETTINGS_SUCCESS,
  payload: settings,
})

export const getSettingsFail = error => ({
  type: GET_SETTINGS_FAIL,
  payload: error,
})


export const updateSettings = (settings, id) => ({
  type: UPDATE_SETTINGS,
  payload: {settings, id},
})

export const updateSettingsSuccess = settings => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload: settings,
})

export const updateSettingsFail = error => ({
  type: UPDATE_SETTINGS_FAIL,
  payload: error,
})

