import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import LocationPicker from "react-leaflet-location-picker"
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//actions
import { getShopDetails, updateShop } from "store/actions"
import { getUsers } from "store/users/actions"
import MetaTag from "components/Common/Meta-tag"

const UpdateShop = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { shopDetails, loading, createShopError } = useSelector(state => ({
    createShopError: state.Shops.createShopError,
    shopDetails: state.Shops.shopDetails,
    loading: state.Shops.loading,
  }))

  const { users } = useSelector(state => ({
    users: state.Users.users?.users,
  }))

  //assigning real values to mainValue
  let mainValue = shopDetails?.location?.coordinates || [11.0408, 76.0849]

  //giving mainValue to mapValues
  const [mapValues, setMapValues] = useState(mainValue)

  function handleValidSubmit(values) {
    values.shippingPrice = parseFloat(values.shippingPrice)
    values.location.coordinates[0] = values.location?.coordinates[0]
    values.location.coordinates[1] = values.location?.coordinates[1]

    dispatch(updateShop(values, shopDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getShopDetails(params?.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getUsers(params?._id))
  }, [dispatch])

  const pointVals = [mapValues]
  const pointMode = {
    banner: false,
    control: {
      values: pointVals,
      onClick: point => setMapValues(point),
    },
  }

  return (
    <>
      <MetaTag title="Update Shop" />

      <div className="page-content">
        <Breadcrumbs title="Shops" breadcrumbItem="Update Shop" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12} md={12}>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(v)
                  }}
                >
                  {createShopError && (
                    <Alert color="danger">{createShopError}</Alert>
                  )}

                  <Row>
                    <Col className="d-flex align-items-stretch">
                      <Card>
                        <CardBody>
                          <Row>
                            <Label className="mx-3">Dealer Details:</Label>
                            <Col md="12">
                              <div className="d-flex flex-wrap">
                                <div className="col-12 mt-3 px-3">
                                  <AvField
                                    type="select"
                                    name="dealer"
                                    errorMessage="Select a user"
                                    helpMessage="Select a user"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={shopDetails?.dealer || ""}
                                  >
                                    {users?.map((item, key) => (
                                      <option key={key} value={item?._id}>
                                        {item?._id}
                                      </option>
                                    ))}
                                  </AvField>
                                </div>
                                <div className="col-12 mt-3 px-3">
                                  <AvField
                                    name="dealerDetails.name"
                                    placeholder="Dealer name"
                                    type="text"
                                    errorMessage="Invalid dealer name"
                                    value={
                                      shopDetails?.dealerDetails?.name || ""
                                    }
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-3 px-3">
                                  <AvField
                                    name="dealerNo"
                                    placeholder="Dealer No."
                                    type="text"
                                    errorMessage="Invalid dealer No."
                                    value={shopDetails?.dealerNo || ""}
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-3 px-3">
                                  <AvField
                                    name="dealerDetails.contactNumber"
                                    placeholder="Contact No."
                                    type="text"
                                    errorMessage="Invalid phone no."
                                    value={
                                      shopDetails?.dealerDetails
                                        ?.contactNumber || ""
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3 mt-1 px-3">
                                  <AvField
                                    name="dealerDetails.address.district"
                                    placeholder="District"
                                    type="text"
                                    errorMessage="Invalid address"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.dealerDetails?.address
                                        ?.district || ""
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3 mt-1 px-3">
                                  <AvField
                                    name="dealerDetails.address.addressLine1"
                                    placeholder="Address line 1"
                                    type="text"
                                    errorMessage="Invalid address"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.dealerDetails?.address
                                        ?.addressLine1 || ""
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3 mt-1 px-3">
                                  <AvField
                                    name="dealerDetails.address.addressLine2"
                                    placeholder="Address line 2"
                                    type="text"
                                    errorMessage="Invalid address"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.dealerDetails?.address
                                        ?.addressLine2 || ""
                                    }
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-1 px-3">
                                  <AvField
                                    name="dealerDetails.address.landmark"
                                    placeholder="Landmark"
                                    type="text"
                                    errorMessage="Invalid landmark"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.dealerDetails?.address
                                        ?.landmark || ""
                                    }
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-1 px-3">
                                  <AvField
                                    name="dealerDetails.address.area"
                                    placeholder="Area"
                                    type="text"
                                    errorMessage="Invalid area"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.dealerDetails?.address
                                        ?.area || ""
                                    }
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-1 px-3">
                                  <AvField
                                    name="dealerDetails.address.state"
                                    placeholder="State"
                                    type="text"
                                    errorMessage="Invalid state"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.dealerDetails?.address
                                        ?.state || ""
                                    }
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-1 px-3">
                                  <AvField
                                    name="dealerDetails.address.pinCode"
                                    placeholder="Pin code"
                                    type="text"
                                    errorMessage="Invalid pin code"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.dealerDetails?.address
                                        ?.pinCode || ""
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="d-flex align-items-stretch">
                      <Card>
                        <CardBody>
                          <Row>
                            <Label className="mx-3">Shop Details:</Label>
                            <Col md="12">
                              <div className="d-flex flex-wrap">
                                <div className="col-12 mt-3 px-3">
                                  <AvField
                                    name="shopName"
                                    placeholder="Shop Name"
                                    type="text"
                                    errorMessage="Invalid shop name"
                                    value={shopDetails?.shopName || ""}
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-3 px-3">
                                  <AvField
                                    name="shopNo"
                                    placeholder="Shop No."
                                    type="text"
                                    errorMessage="Invalid shop No."
                                    value={shopDetails?.shopNo || ""}
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-3 px-3">
                                  <AvField
                                    name="contactNumber"
                                    placeholder="Contact Number"
                                    type="text"
                                    errorMessage="Invalid contact number"
                                    value={shopDetails?.contactNumber || ""}
                                  />
                                </div>
                                <div className="col-12 mb-3 mt-1 px-3">
                                  <AvField
                                    name="shopAddress.district"
                                    placeholder="District"
                                    type="text"
                                    errorMessage="Invalid district"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.shopAddress?.district || ""
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3 mt-1 px-3">
                                  <AvField
                                    name="shopAddress.addressLine1"
                                    placeholder="Address line 1"
                                    type="text"
                                    errorMessage="Invalid address"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.shopAddress?.addressLine1 ||
                                      ""
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3 mt-1 px-3">
                                  <AvField
                                    name="shopAddress.addressLine2"
                                    placeholder="Address line 2"
                                    type="text"
                                    errorMessage="Invalid address"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.shopAddress?.addressLine2 ||
                                      ""
                                    }
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-1 px-3">
                                  <AvField
                                    name="shopAddress.landmark"
                                    placeholder="Landmark"
                                    type="text"
                                    errorMessage="Invalid landmark"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.shopAddress?.landmark || ""
                                    }
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-1 px-3">
                                  <AvField
                                    name="shopAddress.area"
                                    placeholder="Area"
                                    type="text"
                                    errorMessage="Invalid area"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={shopDetails?.shopAddress?.area || ""}
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-1 px-3">
                                  <AvField
                                    name="shopAddress.state"
                                    placeholder="State"
                                    type="text"
                                    errorMessage="Invalid state"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      shopDetails?.shopAddress?.state || ""
                                    }
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-1 px-3">
                                  <AvField
                                    name="shopAddress.pinCode"
                                    placeholder="Pin code"
                                    type="text"
                                    errorMessage="Invalid pin code"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      `${shopDetails?.shopAddress?.pinCode}` ||
                                      ""
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3 mt-1 px-3">
                                  <AvField
                                    name="workingTime"
                                    placeholder="Working Time"
                                    type="text"
                                    errorMessage="Invalid Working time"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={shopDetails?.workingTime || ""}
                                  />
                                </div>
                                <div className="col-12 mb-3 mt-1 px-3">
                                  <AvField
                                    name="shippingPrice"
                                    placeholder="shipping price (₹)"
                                    type="text"
                                    errorMessage="Invalid shipping price"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={shopDetails?.shippingPrice || "0"}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Card>
                        <CardBody className="d-flex">
                          <Row>
                            <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                              <LocationPicker
                                name="location.coordinates.mapValues"
                                pointMode={pointMode}
                                showInputs={false}
                                mapStyle={{
                                  height: "400px",
                                  width: "350px",
                                  borderRadius: "5px",
                                }}

                                // value={mapValues}
                              />
                            </Col>
                            <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="col-6 mt-5 mx-5">
                                <Label>
                                  <i className="bx bx-map"></i> Location (
                                  latitude / longitude )
                                </Label>
                              </div>
                              <div className="col-6 mb-3 mt-2 mx-5">
                                <AvField
                                  name="location.coordinates[0]"
                                  placeholder="Latitude"
                                  type="text"
                                  id="#geolocation"
                                  errorMessage="Invalid latitude"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={mapValues[0]}
                                />
                              </div>
                              <div className="col-6 mb-3 mt-3 mx-5">
                                <AvField
                                  name="location.coordinates[1]"
                                  placeholder="Longitude"
                                  type="text"
                                  errorMessage="Invalid longitude"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={mapValues[1]}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Update Shop
                            </button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateShop
