import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Card, CardBody, CardSubtitle, CardTitle, Col } from "reactstrap"
import { Row, TabContent, TabPane, Container } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import ImageCard from "components/Common/ImageCardCreate"

//actions
import { createCategory } from "store/actions"
import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

const CreateCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.Category.loading,
  }))

  const [logoImages, setLogoImages] = useState([])
  const [logoImagesPreview, setLogoImagesPreview] = useState([])

  const [bannerImages, setBannerImages] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])

  const [activeTab, setActiveTab] = useState("1")

  function handleValidSubmit(values) {
    const createCategoryData = {
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      priority: parseInt(values.priority),
      logoImages: logoImages,
      bannerImages: bannerImagesPreview?.length >= 1 ? bannerImages : "",
    }
    dispatch(createCategory(createCategoryData, history))
  }

  const onChangeLogoImageHandler = e => {
    const files = Array.from(e.target.files)

    setLogoImagesPreview([])
    setLogoImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setLogoImagesPreview(oldArray => [...oldArray, reader.result])
          setLogoImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const onChangeBannerImageHandler = e => {
    const files = Array.from(e.target.files)

    setBannerImagesPreview([])
    setBannerImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setBannerImagesPreview(oldArray => [
            ...oldArray,
            { url: reader.result, id: oldArray.length + 1 },
          ])
          setBannerImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const onDeleteImg = id => {
    setBannerImagesPreview(bannerImagesPreview?.filter(item => item.id !== id))
  }

  const isMult = LanguageSwitch()?.isMult

  return (
    <>
      <MetaTag title="Create Category" />

      <div className="page-content">
        <Breadcrumbs title="Categorys" breadcrumbItem="Create Category" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="12">
                          <div className="col-3 mb-3">
                            <AvField
                              name="priority"
                              label="Priority"
                              type="number"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {/* lang */}
                <Col xl="12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}

                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Col className="col-12 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <AvField
                                name="primaryLang.name"
                                className=""
                                label="Name"
                                type="text"
                                errorMessage="Invalid product name"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <AvField
                                name="primaryLang.description"
                                label="Description"
                                type="textarea"
                                errorMessage="Invalid description"
                                rows="7"
                              />
                            </div>
                          </Col>
                        </TabPane>

                        <TabPane tabId="2">
                          <Col className="col-12 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <div className="d-flex justify-content-between mb-2">
                                <CardSubtitle>Name</CardSubtitle>
                                <CardSubtitle>اسم</CardSubtitle>
                              </div>
                              <AvField
                                style={{ textAlign: "right" }}
                                name="secondaryLang.name"
                                label=""
                                type="text"
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <div className="d-flex justify-content-between mb-2">
                                <CardSubtitle>Description</CardSubtitle>
                                <CardSubtitle>وصف</CardSubtitle>
                              </div>
                              <AvField
                                style={{ textAlign: "right" }}
                                name="secondaryLang.description"
                                label=""
                                type="textarea"
                                rows="7"
                              />
                            </div>
                          </Col>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  <ImageCard
                    onDeleteImg={onDeleteImg}
                    bannerImagesPreview={bannerImagesPreview}
                    logoImagesPreview={logoImagesPreview}
                    onChangeBannerImageHandler={onChangeBannerImageHandler}
                    onChangeLogoImageHandler={onChangeLogoImageHandler}
                  />
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Cateory
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateCategory
