import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
  GET_GENDER_TYPES, 
  CREATE_GENDER_TYPE,
  GET_GENDER_TYPE_DETAILS, 
  DELETE_GENDER_TYPE, 
  UPDATE_GENDER_TYPE } from "./actionTypes"
import {
  getGenderTypesSuccess,
  getGenderTypesFail,
  getGenderTypeDetailsSuccess,
  getGenderTypeDetailsFail,
  createGenderTypeFail,
  createGenderTypeSuccess,
  updateGenderTypeSuccess,
  updateGenderTypeFail,
  deleteGenderTypeSuccess,
  deleteGenderTypeFail,
} from "./actions"

//Include Both Helper File with needed methods
// import { getCategories, getProjectsDetails, addNewProject, updateProject, deleteProject } from "helpers/fakebackend_helper"
import { post, get, ApiPut, del } from "../../helpers/api_helper"
import {Notification} from "../../components/Common/Notification"

function genderTypeApi() {
  return get("/genderType/admin/all")
}

const getGenderTypeDetailsAPi = genderTypeId => {
  return get(`/genderType/admin/${genderTypeId}`)
}

function createGenderTypeApi({genderType}) {
  return post("/genderType/admin/new", genderType)
}

function updateGenderTypeApi({genderType, genderTypeId}) {
  return ApiPut(`/genderType/admin/${genderTypeId}`, genderType)
}

function deleteGenderTypeApi(genderTypeId) {
  return del(`/genderType/admin/${genderTypeId}`)
}



function* fetchGenderTypes() {
  try {
    const response = yield call(genderTypeApi)
    yield put(getGenderTypesSuccess(response))
  } catch (error) {
    yield put(getGenderTypesFail(error))
  }
}

function* fetchGenderTypeDetails({ payload: genderTypeId }) {
  try {
    const response = yield call(getGenderTypeDetailsAPi, genderTypeId)
    yield put(getGenderTypeDetailsSuccess(response))
  } catch (error) {
    yield put(getGenderTypeDetailsFail(error))
  }
}

function* onCreateGenderType({ payload }) {

  try {
    const response = yield call(createGenderTypeApi, payload)
    yield put(createGenderTypeSuccess(response))
    Notification({
      type: "success",
      message: "GenderType Created Successfully!",
      title: ""
    })
    payload.history.goBack();
  } catch (error) {

    yield put(createGenderTypeFail(error))
  }
}

function* onUpdateGenderType({ payload}) {
  try {
    const response = yield call(updateGenderTypeApi, payload)
    yield put(updateGenderTypeSuccess(response))
    Notification({
      type: "success",
      message: "GenderType Updated Successfully!",
      title: ""
    })
    payload.history.goBack();
  } catch (error) {
    yield put(updateGenderTypeFail(error))
  }
}

function* onDeleteGenderType({ genderTypeId, history }) {
  try {
    const response = yield call(deleteGenderTypeApi, genderTypeId)
    yield put(deleteGenderTypeSuccess(response))
    Notification({
      type: "error",
      message: "GenderType Deleted Successfully!",
      title: ""
    })
    history.goBack();
  } catch (error) {
    yield put(deleteGenderTypeFail(error))
  }
}



function* genderTypeSaga() {
  yield takeEvery(GET_GENDER_TYPES, fetchGenderTypes)
  yield takeEvery(GET_GENDER_TYPE_DETAILS, fetchGenderTypeDetails)
  yield takeEvery(CREATE_GENDER_TYPE, onCreateGenderType)
  yield takeEvery(UPDATE_GENDER_TYPE, onUpdateGenderType)
  yield takeEvery(DELETE_GENDER_TYPE, onDeleteGenderType)
}

export default genderTypeSaga
