import { DELETE_AGEGROUP_SUCCESS } from "store/ageGroup/actionTypes"
import { DELETE_BRAND_SUCCESS } from "store/brand/actionTypes"
import { DELETE_CATEGORY_SUCCESS } from "store/category/actionTypes"
import { DELETE_GENDER_TYPE_SUCCESS } from "store/genderType/actionTypes"
import { DELETE_SUBCATEGORY_SUCCESS } from "store/subCategory/actionTypes"
import {
  GET_ALL_CLASSIFICATIONS,
  GET_ALL_CLASSIFICATIONS_FAIL,
  GET_ALL_CLASSIFICATIONS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  classifications: [],
  error: {},
  loading: false,
}

const Category = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CLASSIFICATIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        classifications: action.payload.classifications,
        total: action.payload.total,
        loading: false,
      }

    case GET_ALL_CLASSIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //category
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        classifications: state.classifications.filter(
          category => category._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    //subCategory
    case DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        classifications: state.classifications.filter(
          subCategory =>
            subCategory._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }
    //genderTypes
    case DELETE_GENDER_TYPE_SUCCESS:
      return {
        ...state,
        classifications: state.classifications.filter(
          genderType =>
            genderType._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    //brand
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        classifications: state.classifications.filter(
          brand => brand._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    //agegroup
    case DELETE_AGEGROUP_SUCCESS:
      return {
        ...state,
        classifications: state.classifications.filter(
          ageGroup => ageGroup._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    default:
      return state
  }
}

export default Category
