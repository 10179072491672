import { Notification } from "components/Common/Notification"
import PropTypes from "prop-types"

function HandleError(error) {
  switch (error?.data?.message) {
    case "No user in this Token #admin":
      localStorage?.clear()
      window.location.reload()
      Notification({
        type: "error",
        message: error?.message,
        title: "",
        timeOut: 5000,
      })
      break
    default:
      return error
  }
}

export default HandleError

HandleError.propTypes = {
  error: PropTypes.any,
}
