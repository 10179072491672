import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import moment from "moment"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Media,
  Button,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//actions
import { getOrders, getOrderDetails, updateOrder } from "store/orders/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { CurrencySwitch } from "common/Currency"
import OrderItemTable from "./OrderItemTable"
import MetaTag from "components/Common/Meta-tag"
import { AvField, AvForm } from "availity-reactstrap-validation"

const OrderDetail = props => {
  const dispatch = useDispatch()
  const params = useParams()

  const { orderDetails } = useSelector(state => ({
    orderDetails: state.Orders.orderDetails,
  }))

  useEffect(() => {
    dispatch(getOrders())
  }, [dispatch])

  useEffect(() => {
    dispatch(getOrderDetails(params.id))
  }, [dispatch])

  const currecny = CurrencySwitch()

  const orderStatus = [
    "Processing",
    // "Fulfilled",
    "Shipped",
    "Delivered",
    "Cancelled",
    // "Cancel Processing",
    "Returned",
    // "Return Processing",
  ]

  const handleValidSubmit = (e, v) => {
    e.preventDefault()
    const updateOrders = {
      orderId: orderDetails?._id,
      orderStatus: v.orderStatus,
    }
    console.log("data :", updateOrders)
    dispatch(updateOrder(updateOrders, history))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="Order Details" />
        <Container fluid>
          <Breadcrumbs title="Order" breadcrumbItem="Order Details" />
          {orderDetails?._id && (
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="8">
                          <Media>
                            <Media className="align-self-center" body>
                              <div className="text-muted">
                                <p className="mb-0">
                                  Order ID : {orderDetails?.orderId}
                                </p>
                                <h5 className="mb-1">
                                  {orderDetails?.shippingInfo?.name}
                                </h5>
                                <Col sm="12" lg="8" className="mt-3">
                                  <p>
                                    <i className="bx bx-map me-2"></i>
                                    {orderDetails?.shippingInfo?.addressLine1
                                      ? `${
                                          orderDetails?.shippingInfo
                                            ?.addressLine1
                                        }${","}`
                                      : ""}{" "}
                                    {orderDetails?.shippingInfo?.addressLine2
                                      ? `${
                                          orderDetails?.shippingInfo
                                            ?.addressLine2
                                        }${","}`
                                      : ""}{" "}
                                    {orderDetails?.shippingInfo?.street
                                      ? `${
                                          orderDetails?.shippingInfo?.street
                                        }${","}`
                                      : ""}{" "}
                                    {orderDetails?.shippingInfo?.landmark
                                      ? `${
                                          orderDetails?.shippingInfo?.landmark
                                        }${","}`
                                      : ""}{" "}
                                    {orderDetails?.shippingInfo?.area
                                      ? `${
                                          orderDetails?.shippingInfo?.area
                                        }${","}`
                                      : ""}{" "}
                                    <br /> &nbsp; &nbsp; &nbsp;{" "}
                                    {orderDetails?.shippingInfo?.district
                                      ? `${
                                          orderDetails?.shippingInfo?.district
                                        }${","}`
                                      : ""}{" "}
                                    {orderDetails?.shippingInfo?.state
                                      ? `${
                                          orderDetails?.shippingInfo?.state
                                        }${","}`
                                      : ""}{" "}
                                    {orderDetails?.shippingInfo?.pinCode
                                      ? `${orderDetails?.shippingInfo?.pinCode}`
                                      : ""}
                                  </p>
                                </Col>

                                <p className="mb-0">
                                  <i className="fas fa-phone me-2"></i>Phone:{" "}
                                  {orderDetails?.shippingInfo?.contactNumber}
                                </p>
                              </div>
                            </Media>
                          </Media>
                        </Col>

                        <Col lg="4" className="">
                          <Media>
                            <Media body>
                              <div className="text-muted">
                                <div>
                                  <p>
                                    Order Date: &nbsp;
                                    {moment(orderDetails?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>

                                {/* <div className="text-muted">
                                  <div>
                                    <i
                                      className="bx bxs-star"
                                      style={{
                                        fontSize: "30px",
                                        color: orderDetails?.isStarred
                                          ? "#75798e"
                                          : "#fff",
                                      }}
                                    ></i>
                                  </div>
                                </div> */}
                                <div className="">
                                  <AvForm
                                    className="form-horizontal "
                                    onValidSubmit={(e, v) => {
                                      handleValidSubmit(e, v)
                                    }}
                                  >
                                    <div className="d-flex flex-wrap w-full">
                                      <AvField
                                        type="select"
                                        name="orderStatus"
                                        style={{
                                          fontSize: "12px",
                                          width: "100%",
                                        }}
                                        className="text-secondary newClass"
                                        value={orderDetails?.orderStatus}
                                      >
                                        {orderStatus?.map((status, key) => (
                                          <option key={key} value={status}>
                                            {status}
                                          </option>
                                        ))}
                                      </AvField>

                                      <Button
                                        type="submit"
                                        color="black"
                                        className="ms-1 btn text-success"
                                        style={{
                                          border: "1px solid whitesmoke",
                                        }}
                                      >
                                        Update Order
                                      </Button>
                                    </div>
                                  </AvForm>
                                </div>
                              </div>
                            </Media>
                          </Media>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="col-12">
                    <CardBody>
                      <Label>
                        Order Items ({orderDetails?.orderItems?.length}):
                      </Label>
                      <OrderItemTable />

                      <Row>
                        <Col>
                          <hr />
                          <Col className="text-end">
                            <h5 className="mb-2  text-muted text-end">
                              Item Total:
                              <span className="mx-3 text-black  text-end">
                                {currecny}{" "}
                                {orderDetails?.itemsPrice?.toFixed(2)}
                              </span>
                            </h5>
                            <h5 className="mb-2 text-muted text-end">
                              &nbsp;Shipping:&nbsp;&nbsp;
                              <span className="mx-3  text-black text-end">
                                {currecny}{" "}
                                {orderDetails?.shippingPrice?.toFixed(2)}
                              </span>
                            </h5>
                            <h5 className="mb-1 text-muted text-end">
                              Total Price:
                              <span className="mx-3 text-black text-end">
                                {currecny}{" "}
                                {orderDetails?.totalPrice?.toFixed(2)}
                              </span>
                            </h5>
                          </Col>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>{" "}
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

OrderDetail.propTypes = {
  order: PropTypes.object,
  match: PropTypes.any,
  onGetOrderDetail: PropTypes.func,
}

export default OrderDetail
