import {
  GET_SHOP_PRODUCTS,
  GET_SHOP_PRODUCTS_SUCCESS,
  GET_SHOP_PRODUCTS_FAIL,
  GET_DELETED_SHOP_PRODUCTS,
  GET_DELETED_SHOP_PRODUCTS_SUCCESS,
  GET_DELETED_SHOP_PRODUCTS_FAIL,
  GET_SHOP_PRODUCT_DETAILS,
  GET_SHOP_PRODUCT_DETAILS_SUCCESS,
  GET_SHOP_PRODUCT_DETAILS_FAIL,
  CREATE_SHOP_PRODUCT,
  CREATE_SHOP_PRODUCT_SUCCESS,
  CREATE_SHOP_PRODUCT_FAIL,
  UPDATE_SHOP_PRODUCT,
  UPDATE_SHOP_PRODUCT_SUCCESS,
  UPDATE_SHOP_PRODUCT_FAIL,
  CHANGE_SHOP_PRODUCT_STATUS,
  CHANGE_SHOP_PRODUCT_STATUS_SUCCESS,
  CHANGE_SHOP_PRODUCT_STATUS_FAIL,
  DELETE_SHOP_PRODUCT,
  DELETE_SHOP_PRODUCT_SUCCESS,
  DELETE_SHOP_PRODUCT_FAIL,
  HARD_DELETE_SHOP_PRODUCT,
  HARD_DELETE_SHOP_PRODUCT_SUCCESS,
  HARD_DELETE_SHOP_PRODUCT_FAIL,
  RESTORE_SHOP_PRODUCT,
  RESTORE_SHOP_PRODUCT_SUCCESS,
  RESTORE_SHOP_PRODUCT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  shopProducts: [],
  total: 0,
  deletedShopProducts: [],
  shopProductDetails: {},
  productVarients: {},
  reviews:[],
  error: {},
  loading: false,
  createShopProductError: null
}

const ShopProducts = (state = INIT_STATE, action) => {
  
  switch (action.type) {

    case GET_SHOP_PRODUCTS:
    case GET_DELETED_SHOP_PRODUCTS:
    case GET_SHOP_PRODUCT_DETAILS:
    case CREATE_SHOP_PRODUCT:
    case UPDATE_SHOP_PRODUCT:
    case DELETE_SHOP_PRODUCT:
    case HARD_DELETE_SHOP_PRODUCT:
    case RESTORE_SHOP_PRODUCT:
      return {
        ...state,
        loading: true,
      }
    
    case GET_SHOP_PRODUCTS_SUCCESS:
      return {
        ...state,
        shopProducts: action.payload.shopProducts,
        total: action.payload.total,
        loading: false,
      }
      

    case GET_SHOP_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DELETED_SHOP_PRODUCTS_SUCCESS:
      return {
        ...state,
        deletedShopProducts: action.payload.data,
        loading: false,
      }
      

    case GET_DELETED_SHOP_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    
    case GET_SHOP_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        shopProductDetails: action.payload.shopProduct,
        productVarients: action.payload.productVarients,
        reviews: action.payload.reviews,
        loading: false,
      }

    case GET_SHOP_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_SHOP_PRODUCT_SUCCESS:
      return {
        ...state,
        shopProducts: [...state.shopProducts, action.payload],
        createShopProductError: null,
        loading: false,
      }

    case CREATE_SHOP_PRODUCT_FAIL:
      return {
        ...state,
        createShopProductError: action.payload,
        loading: false,
      }

    case UPDATE_SHOP_PRODUCT_SUCCESS:
    return {
      ...state,
      shopProducts: [...state.shopProducts].map(shopProduct =>
        shopProduct._id.toString() === action.payload.shopProducts_id.toString()
          ? { shopProduct, ...action.payload.shopProducts }
          : shopProduct
      ),
      loading: false,
      }
      

    case UPDATE_SHOP_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CHANGE_SHOP_PRODUCT_STATUS_SUCCESS:
      return {
        ...state,
        shopProducts: [...state.shopProducts].map(shopProduct =>
          shopProduct._id.toString() === action.payload.data._id.toString()
            ? { shopProduct, ...action.payload.data }
            : shopProduct
        ),
        loading: false,
        }

    case CHANGE_SHOP_PRODUCT_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case DELETE_SHOP_PRODUCT_SUCCESS:
      return {
        ...state,
        shopProducts: state.shopProducts.filter(
          shopProduct => shopProduct._id.toString() !== action.payload.data._id.toString()
        ),
        loading: false,
      }

    case DELETE_SHOP_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

      case HARD_DELETE_SHOP_PRODUCT_SUCCESS:
      return {
        ...state,
        deletedShopProducts: state.deletedShopProducts.filter(
          shopProduct => shopProduct._id.toString() !== action.payload.data._id.toString()
        ),
        loading: false,
      }

    case HARD_DELETE_SHOP_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case RESTORE_SHOP_PRODUCT_SUCCESS:
      return {
        ...state,
        deletedShopProducts: state.deletedShopProducts.filter(
          shopProduct => shopProduct._id.toString() !== action.payload.data._id.toString()
        ),
        loading: false,
      }

    case RESTORE_SHOP_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default ShopProducts
