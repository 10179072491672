import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ENQUIRIES,
  GET_ENQUIRIES_CARDS,
  GET_ENQUIRY_DETAIL,
  CREATE_ENQUIRY,
  UPDATE_ENQUIRY,
  DELETE_ENQUIRY,
} from "./actionTypes"
import {
  getEnquiriesSuccess,
  getEnquiriesFail,
  getEnquiriesCardsSuccess,
  getEnquiriesCardsFail,
  getEnquiryDetailSuccess,
  getEnquiryDetailFail,
  createEnquirySuccess,
  createEnquiryFail,
  updateEnquirySuccess,
  updateEnquiryFail,
  deleteEnquirySuccess,
  deleteEnquiryFail,
} from "./actions"
import { Notification } from "components/Common/Notification"
import { ApiPut, del, get, post } from "helpers/api_helper"

const getEnquiriesAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(`/enquiry/admin/all?search=${searchText && searchText}`)
  } else {
    return get(`/enquiry/admin/all`)
  }
}
// const getEnquiriesCardsAPi = () => {
//   return get(`/api/v1/enquiry/enquiry/?cards=all`)
// }
const getEnquiryDetailsAPi = enquiryId => {
  return get(`/enquiry/admin/${enquiryId}/`)
}
const createEnquiryApi = ({ enquiry }) => {
  return post("/enquiry/admin/new", enquiry)
}
const updateEnquiryApi = ({ enquiryId, enquiry }) => {
  return ApiPut(`/enquiry/admin/${enquiryId}/`, enquiry)
}
const deleteEnquiryApi = ({ enquiryId }) => {
  return del(`/enquiry/admin/${enquiryId}/`)
}

function* fetchEnquiries({ payload }) {
  try {
    const response = yield call(getEnquiriesAPi, payload)
    yield put(getEnquiriesSuccess(response))
  } catch (error) {
    yield put(getEnquiriesFail(error))
  }
}

function* fetchEnquiriesCards() {
  try {
    const response = yield call(getEnquiriesCardsAPi)
    yield put(getEnquiriesCardsSuccess(response))
  } catch (error) {
    yield put(getEnquiriesCardsFail(error))
  }
}

function* fetchEnquiryDetail({ enquiryId }) {
  try {
    const response = yield call(getEnquiryDetailsAPi, enquiryId)
    yield put(getEnquiryDetailSuccess(response))
  } catch (error) {
    yield put(getEnquiryDetailFail(error))
  }
}
function* onCreateEnquiry({ payload }) {
  try {
    const response = yield call(createEnquiryApi, payload)
    yield put(createEnquirySuccess(response))
    if (payload.history) {
      payload.history.push("/enquiries")
    }
    doneNotification()
    //get enquiries
    // try {
    //   const response = yield call(getEnquiriesAPi, payload)
    //   yield put(getEnquiriesSuccess(response))
    // } catch (error) {
    //   yield put(getEnquiriesFail(error))
    // }
  } catch (error) {
    console.log("error: ", error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createEnquiryFail(error))
  }
}

function* onUpdateEnquiry({ payload }) {
  try {
    const response = yield call(updateEnquiryApi, payload)
    payload.history.push("/enquiries")
    yield put(updateEnquirySuccess(response))
    doneNotification()
  } catch (error) {
    console.log("error: ", error)
    yield put(updateEnquiryFail(error))
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
  }
}

function* onDeleteEnquiry({ payload }) {
  try {
    const response = yield call(deleteEnquiryApi, payload)
    // payload.history.push("/enquirys")
    yield put(deleteEnquirySuccess(response))
    doneNotification()
    //get enquiries
    try {
      const response = yield call(getEnquiriesAPi, payload)
      yield put(getEnquiriesSuccess(response))
    } catch (error) {
      yield put(getEnquiriesFail(error))
    }
  } catch (error) {
    errorNotification()
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteEnquiryFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification() {
  Notification({
    type: "success",
    message: "Done",
    title: "",
  })
}

function* EnquiriesSaga() {
  yield takeEvery(GET_ENQUIRIES, fetchEnquiries)
  yield takeEvery(GET_ENQUIRIES_CARDS, fetchEnquiriesCards)
  yield takeEvery(GET_ENQUIRY_DETAIL, fetchEnquiryDetail)
  yield takeEvery(CREATE_ENQUIRY, onCreateEnquiry)
  yield takeEvery(UPDATE_ENQUIRY, onUpdateEnquiry)
  yield takeEvery(DELETE_ENQUIRY, onDeleteEnquiry)
}

export default EnquiriesSaga
