/* ENQUIRIES */
export const GET_ENQUIRIES = "GET_ENQUIRIES"
export const GET_ENQUIRIES_SUCCESS = "GET_ENQUIRIES_SUCCESS"
export const GET_ENQUIRIES_FAIL = "GET_ENQUIRIES_FAIL"

/* ENQUIRIES CARDS*/
export const GET_ENQUIRIES_CARDS = "GET_ENQUIRIES_CARDS"
export const GET_ENQUIRIES_CARDS_SUCCESS = "GET_ENQUIRIES_CARDS_SUCCESS"
export const GET_ENQUIRIES_CARDS_FAIL = "GET_ENQUIRIES_CARDS_FAIL"

/* ENQUIRY DETAIL*/
export const GET_ENQUIRY_DETAIL = "GET_ENQUIRY_DETAIL"
export const GET_ENQUIRY_DETAIL_SUCCESS = "GET_ENQUIRY_DETAIL_SUCCESS"
export const GET_ENQUIRY_DETAIL_FAIL = "GET_ENQUIRY_DETAIL_FAIL"

/**
 * add ENQUIRY
 */
export const CREATE_ENQUIRY = "CREATE_ENQUIRY"
export const CREATE_ENQUIRY_SUCCESS = "CREATE_ENQUIRY_SUCCESS"
export const CREATE_ENQUIRY_FAIL = "CREATE_ENQUIRY_FAIL"

/**
 * Edit ENQUIRY
 */
export const UPDATE_ENQUIRY = "UPDATE_ENQUIRY"
export const UPDATE_ENQUIRY_SUCCESS = "UPDATE_ENQUIRY_SUCCESS"
export const UPDATE_ENQUIRY_FAIL = "UPDATE_ENQUIRY_FAIL"

/**
 * Delete ENQUIRY
 */
export const DELETE_ENQUIRY = "DELETE_ENQUIRY"
export const DELETE_ENQUIRY_SUCCESS = "DELETE_ENQUIRY_SUCCESS"
export const DELETE_ENQUIRY_FAIL = "DELETE_ENQUIRY_FAIL"
