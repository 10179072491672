import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DatatableTables from "./DatatableTables"
import MetaTag from "components/Common/Meta-tag"

function ShopProduct() {
  return (
    <>
      <MetaTag title={"All Shop Products"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Shop Products" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ShopProduct
