import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import { post, get } from "helpers/api_helper"
import {
  errorNotification,
  Notification,
} from "../../../components/Common/Notification"

function loginApi(user) {
  return post("/login", user)
}
function logoutApi() {
  return get("/logout")
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(loginApi, user)

    if (response?.token) {
      localStorage.setItem("token", response?.token)
      yield put(loginSuccess(response))
      Notification({
        type: "success",
        message: "Admin Logged In Successfully!",
        title: "",
      })
      history.push("/dashboard")
    }
  } catch (error) {
    yield put(apiError(error.response.data))
    errorNotification()
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const token = localStorage.getItem("token")
    const response = yield call(logoutApi, token)

    localStorage.clear("token")
    yield put(logoutUserSuccess(response))

    Notification({
      type: "success",
      message: "Logged out successfully",
      title: "",
    })

    history.push("/login")
  } catch (error) {
    yield put(apiError(error.response.data))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
