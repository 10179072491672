import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_BRANDS,
  CREATE_BRAND,
  GET_BRAND_DETAILS,
  DELETE_BRAND,
  UPDATE_BRAND,
} from "./actionTypes"
import {
  getBrandsSuccess,
  getBrandsFail,
  getBrandDetailsSuccess,
  getBrandDetailsFail,
  createBrandFail,
  createBrandSuccess,
  updateBrandSuccess,
  updateBrandFail,
  deleteBrandSuccess,
  deleteBrandFail,
} from "./actions"

//Include Both Helper File with needed methods
// import { getCategories, getProjectsDetails, addNewProject, updateProject, deleteProject } from "helpers/fakebackend_helper"
import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function brandApi() {
  return get("/brand/admin/all")
}

const getBrandDetailsAPi = brandId => {
  return get(`/brand/admin/${brandId}`)
}

function createBrandApi({ brand }) {
  return post("/brand/admin/new", brand)
}

function updateBrandApi({ brand, brandId }) {
  return ApiPut(`/brand/admin/${brandId}`, brand)
}

function deleteBrandApi(brandId) {
  return del(`/brand/admin/${brandId}`)
}

function* fetchBrands() {
  try {
    const response = yield call(brandApi)
    yield put(getBrandsSuccess(response))
  } catch (error) {
    yield put(getBrandsFail(error))
  }
}

function* fetchBrandDetails({ payload: brandId }) {
  try {
    const response = yield call(getBrandDetailsAPi, brandId)
    yield put(getBrandDetailsSuccess(response))
  } catch (error) {
    yield put(getBrandDetailsFail(error))
  }
}

function* onCreateBrand({ payload }) {
  try {
    const response = yield call(createBrandApi, payload)
    yield put(createBrandSuccess(response))
    Notification({
      type: "success",
      message: "Brand Created Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(createBrandFail(error))
  }
}

function* onUpdateBrand({ payload }) {
  try {
    const response = yield call(updateBrandApi, payload)
    yield put(updateBrandSuccess(response))
    Notification({
      type: "success",
      message: "Brand Updated Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(updateBrandFail(error))
  }
}

function* onDeleteBrand({ brandId, history }) {
  try {
    const response = yield call(deleteBrandApi, brandId)
    yield put(deleteBrandSuccess(response))
    Notification({
      type: "error",
      message: "Brand Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteBrandFail(error))
  }
}

function* brandSaga() {
  yield takeEvery(GET_BRANDS, fetchBrands)
  yield takeEvery(GET_BRAND_DETAILS, fetchBrandDetails)
  yield takeEvery(CREATE_BRAND, onCreateBrand)
  yield takeEvery(UPDATE_BRAND, onUpdateBrand)
  yield takeEvery(DELETE_BRAND, onDeleteBrand)
}

export default brandSaga
