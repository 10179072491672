import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import { GET_CHARTS_DATA, GET_DASHBOARD_DATA } from "./actionTypes"
import {
  apiSuccess,
  apiFail,
  getDashboardDataSuccess,
  getDashboardDataFail,
} from "./actions"

import { get } from "helpers/api_helper"

function getDashboardDataAPi() {
  return get("/static/dashboard/data")
}

function* getChartsData({ payload: periodType }) {
  try {
    var response
    yield put(apiSuccess(GET_CHARTS_DATA, response))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* fetchDashboardData() {
  try {
    const response = yield call(getDashboardDataAPi)
    yield put(getDashboardDataSuccess(response))
  } catch (error) {
    yield put(getDashboardDataFail(error))
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])

  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
}

export default dashboardSaga
