/* PROJECTS */
export const GET_BANNERS = "GET_BANNERS"
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS"
export const GET_BANNERS_FAIL = "GET_BANNERS_FAIL"

export const GET_BANNER_DETAILS = "GET_BANNER_DETAILS"
export const GET_BANNER_DETAILS_SUCCESS = "GET_BANNER_DETAILS_SUCCESS"
export const GET_BANNER_DETAILS_FAIL = "GET_BANNER_DETAILS_FAIL"

 export const CREATE_BANNER = "CREATE_BANNER"
 export const CREATE_BANNER_SUCCESS = "CREATE_BANNER_SUCCESS"
 export const CREATE_BANNER_FAIL = "CREATE_BANNER_FAIL"
 
 export const UPDATE_BANNER = "UPDATE_BANNER"
 export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS"
 export const UPDATE_BANNER_FAIL = "UPDATE_BANNER_FAIL"
 
 export const DELETE_BANNER = "DELETE_BANNER"
 export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS"
 export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL"
