/* SHOP_PRODUCTS */
export const GET_SHOP_PRODUCTS = "GET_SHOP_PRODUCTS"
export const GET_SHOP_PRODUCTS_SUCCESS = "GET_SHOP_PRODUCTS_SUCCESS"
export const GET_SHOP_PRODUCTS_FAIL = "GET_SHOP_PRODUCTS_FAIL"

/* DELETED_SHOP_PRODUCTS */
export const GET_DELETED_SHOP_PRODUCTS = "GET_DELETED_SHOP_PRODUCTS"
export const GET_DELETED_SHOP_PRODUCTS_SUCCESS = "GET_DELETED_SHOP_PRODUCTS_SUCCESS"
export const GET_DELETED_SHOP_PRODUCTS_FAIL = "GET_DELETED_SHOP_PRODUCTS_FAIL"

/* SHOP_PRODUCT_DETAILS */
export const GET_SHOP_PRODUCT_DETAILS = "GET_SHOP_PRODUCT_DETAILS"
export const GET_SHOP_PRODUCT_DETAILS_SUCCESS = "GET_SHOP_PRODUCT_DETAILS_SUCCESS"
export const GET_SHOP_PRODUCT_DETAILS_FAIL = "GET_SHOP_PRODUCT_DETAILS_FAIL"

/* CREATE SHOP_PRODUCT*/
 export const CREATE_SHOP_PRODUCT = "CREATE_SHOP_PRODUCT"
 export const CREATE_SHOP_PRODUCT_SUCCESS = "CREATE_SHOP_PRODUCT_SUCCESS"
 export const CREATE_SHOP_PRODUCT_FAIL = "CREATE_SHOP_PRODUCT_FAIL"

/* Edit SHOP_PRODUCT*/
export const UPDATE_SHOP_PRODUCT = "UPDATE_SHOP_PRODUCT"
export const UPDATE_SHOP_PRODUCT_SUCCESS = "UPDATE_SHOP_PRODUCT_SUCCESS"
export const UPDATE_SHOP_PRODUCT_FAIL = "UPDATE_SHOP_PRODUCT_FAIL"

/* Change SHOP_PRODUCT status*/
export const CHANGE_SHOP_PRODUCT_STATUS = "CHANGE_SHOP_PRODUCT_STATUS"
export const CHANGE_SHOP_PRODUCT_STATUS_SUCCESS = "CHANGE_SHOP_PRODUCT_STATUS_SUCCESS"
export const CHANGE_SHOP_PRODUCT_STATUS_FAIL = "CHANGE_SHOP_PRODUCT_STATUS_FAIL"

/* Delete SHOP_PRODUCT*/
export const DELETE_SHOP_PRODUCT = "DELETE_SHOP_PRODUCT"
export const DELETE_SHOP_PRODUCT_SUCCESS = "DELETE_SHOP_PRODUCT_SUCCESS"
export const DELETE_SHOP_PRODUCT_FAIL = "DELETE_SHOP_PRODUCT_FAIL"

/* Hard Delete SHOP_PRODUCT*/
export const HARD_DELETE_SHOP_PRODUCT = "HARD_DELETE_SHOP_PRODUCT"
export const HARD_DELETE_SHOP_PRODUCT_SUCCESS = "HARD_DELETE_SHOP_PRODUCT_SUCCESS"
export const HARD_DELETE_SHOP_PRODUCT_FAIL = "HARD_DELETE_SHOP_PRODUCT_FAIL"

/* Restore SHOP_PRODUCT*/
export const RESTORE_SHOP_PRODUCT = "RESTORE_SHOP_PRODUCT"
export const RESTORE_SHOP_PRODUCT_SUCCESS = "RESTORE_SHOP_PRODUCT_SUCCESS"
export const RESTORE_SHOP_PRODUCT_FAIL = "RESTORE_SHOP_PRODUCT_FAIL"