import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import Details from "./SingleView/index"
import MetaTag from "components/Common/Meta-tag"

function Banner() {
  return (
    <>
      <MetaTag title={" Settings"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Settings" />
        <Container fluid>
          <div className="container-fluid">
            <Details />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Banner
