import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Card, CardBody, CardTitle, Col, Container } from "reactstrap"
import { FormGroup, Row } from "reactstrap"
import { TabContent, TabPane } from "reactstrap"
import Select from "react-select"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import {
  getAllProducts,
  getCollectionsDetails,
  updateCollection,
} from "store/actions"
import { map } from "lodash"
import LanguageTab from "components/Common/LanguageTab"
import ImageCard from "components/Common/ImageCardUpdate"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

const UpdateCollections = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { collectionsDetails, loading, products } = useSelector(state => ({
    loading: state.Collection.loading,
    collectionsDetails: state.Collection.collectionDetails,
    products: state.Products.products,
  }))

  const [logoImages, setLogoImages] = useState([])
  const [logoImagesPreview, setLogoImagesPreview] = useState([])

  const [bannerImages, setBannerImages] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])

  const [searchText, setSearchText] = useState("")
  const [selectedProduct, setSelectedProduct] = useState("Search a Product")
  const [seletedProdData, setSeletedProdData] = useState([])
  const [productIds, setProductIds] = useState([])

  const [productIdsForApiFinal, setProductIdsForApiFinal] = useState()

  const [activeTab, setActiveTab] = useState("1")

  const [oldProductList, setoldProductList] = useState([])

  useEffect(() => {
    setoldProductList(collectionsDetails?.products?.map(s => s._id))
  }, [collectionsDetails])

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", searchText))
  }, [searchText])

  useEffect(() => {
    var modified = oldProductList
    modified = modified?.concat(productIds)
    setProductIdsForApiFinal(modified)
  }, [oldProductList, productIds])

  function handleValidSubmit(values) {
    const updateCollectionData = {
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      logoImages: logoImages,
      bannerImages: bannerImages,
      products: productIdsForApiFinal,
    }
    console.log(updateCollectionData)
    dispatch(
      updateCollection(updateCollectionData, collectionsDetails?._id, history)
    )
  }

  const onChangeImageHandler = e => {
    const files = Array.from(e.target.files)

    setBannerImagesPreview([])
    setBannerImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setBannerImagesPreview(oldArray => [
            ...oldArray,
            { url: reader.result, id: oldArray.length + 1 },
          ])
          setBannerImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const onChangeLogoImageHandler = e => {
    const files = Array.from(e.target.files)

    setLogoImagesPreview([])
    setLogoImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setLogoImagesPreview(oldArray => [...oldArray, reader.result])
          setLogoImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  useEffect(() => {
    dispatch(getCollectionsDetails(params?.id))
  }, [dispatch])

  //selecte product and dropdown
  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  function handlerProductFinalValue(event) {
    setSelectedProduct(event.label)
    setSeletedProdData([...seletedProdData, event])
    setProductIds([...productIds, event.value])
  }

  const productOptions = [
    {
      options:
        products.length &&
        products?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.name,
          value: result._id,
          images: result?.images[0]?.url,
        })),
    },
  ]

  const handleRemoveSeletedproduct = id => {
    setSeletedProdData(seletedProdData?.filter(i => i.value !== id))
    setProductIds(productIds?.filter(i => i !== id))
  }

  const BannerImage = collectionsDetails?.bannerImages
  const LogoImage = collectionsDetails?.logoImages

  const onDeleteImg = id => {
    setBannerImagesPreview(bannerImagesPreview?.filter(item => item.id !== id))
  }

  const handleRemoveOldproduct = id => {
    setoldProductList(oldProductList?.filter(i => i !== id))
  }

  const [allProducts, setAllProducts] = useState([])

  useEffect(() => {
    setAllProducts(
      collectionsDetails?.products?.filter(product =>
        oldProductList?.includes(product?._id)
      )
    )
  }, [oldProductList])

  const isMult = LanguageSwitch()?.isMult

  useEffect(() => {
    setLogoImages(collectionsDetails?.logoImages)
    setBannerImages(collectionsDetails?.bannerImages)
  }, [collectionsDetails])

  return (
    <>
      <MetaTag title={"Update Collections"} />

      <div className="page-content">
        <Breadcrumbs
          title="Classifications"
          breadcrumbItem="Update Collections"
        />
        <Container fluid>
          <div className="container-fluid mb-5">
            <Row>
              <Col lg={12} md={12}>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(v)
                  }}
                >
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        {isMult && (
                          <LanguageTab
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                          />
                        )}
                        <TabContent activeTab={activeTab} className="">
                          <TabPane tabId="1">
                            <Row>
                              <Col className="col-12 d-flex flex-wrap">
                                <Col className="col-12 d-flex flex-wrap">
                                  <div className="col-12 mb-3">
                                    <AvField
                                      name="primaryLang.title"
                                      label="Title"
                                      type="text"
                                      errorMessage="Invalid product name"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      value={
                                        collectionsDetails?.primaryLang
                                          ?.title || ""
                                      }
                                    />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <AvField
                                      name="primaryLang.description"
                                      label="Description"
                                      type="textarea"
                                      errorMessage="Invalid description"
                                      rows="7"
                                      value={
                                        collectionsDetails?.primaryLang
                                          ?.description || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane tabId="2">
                            <Row>
                              <Col xl="12">
                                <Row>
                                  <Col className="col-12 d-flex flex-wrap">
                                    <Col className="col-12 d-flex flex-wrap">
                                      <div className="col-12 mb-3">
                                        <AvField
                                          style={{ textAlign: "right" }}
                                          name="secondaryLang.title"
                                          label="Title"
                                          type="text"
                                          value={
                                            collectionsDetails?.secondaryLang
                                              ?.title || ""
                                          }
                                        />
                                      </div>
                                      <div className="col-12 mb-3">
                                        <AvField
                                          style={{ textAlign: "right" }}
                                          name="secondaryLang.description"
                                          label="Description"
                                          type="textarea"
                                          rows="7"
                                          value={
                                            collectionsDetails?.secondaryLang
                                              ?.description || ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <Row>
                          {/* <Col xl="4" lg="4">
                            <CardTitle
                              className="h4 mb-4"
                              style={{ color: "#1e81b0" }}
                            >
                              Priority
                            </CardTitle>
                            <div className="mb-3">
                              <AvField
                                name="priority"
                                label=""
                                value={collectionsDetails?.priority}
                                type="number"
                              />
                            </div>
                          </Col> */}
                          <Col xl="4" lg="4" className="mb-3">
                            <CardTitle
                              className="h4 mb-4 "
                              style={{ color: "#1e81b0" }}
                            >
                              Products
                            </CardTitle>
                            <FormGroup className="mb-3">
                              <div className="col-md-12"></div>
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleEnters}
                                  value={selectedProduct}
                                  placeholder={"Search a Product"}
                                  onChange={handlerProductFinalValue}
                                  options={productOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={true}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <CardTitle
                          className="h4 mb-2 "
                          style={{ color: "#1e81b0" }}
                        >
                          Selected Products
                        </CardTitle>
                        {seletedProdData.length >= 1 && (
                          <>
                            {map(seletedProdData, (item, key) => (
                              <div key={key} className="mb-2">
                                <div className="d-flex align-items-center">
                                  <img
                                    className="img-thumbnail rounded-circle "
                                    style={{ height: "60px", width: "60px" }}
                                    src={item.images}
                                    alt="product"
                                  />
                                  <p className="pb-0 mb-0 px-3">{item.label}</p>
                                  <i
                                    className="bx bx-trash-alt "
                                    style={{
                                      fontSize: "1rem",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleRemoveSeletedproduct(item.value)
                                    }
                                  ></i>
                                </div>
                              </div>
                            ))}
                          </>
                        )}

                        {map(allProducts, (item, key) => (
                          <div key={key} className="mb-2">
                            <div className="d-flex align-items-center">
                              <img
                                className="img-thumbnail rounded-circle "
                                style={{ height: "60px", width: "60px" }}
                                src={item?.images[0]?.url}
                                alt="product"
                              />
                              <p className="pb-0 mb-0 px-3">
                                {item?.primaryLang.name}
                              </p>
                              <i
                                className="bx bx-trash-alt "
                                style={{
                                  fontSize: "1rem",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveOldproduct(item._id)}
                              ></i>
                            </div>
                          </div>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>

                  <Row>
                    <Col className="col-12">
                      <ImageCard
                        LogoImage={LogoImage}
                        BannerImage={BannerImage}
                        onChangeImageHandler={onChangeImageHandler}
                        imagesPreview={bannerImagesPreview}
                        logoImagesPreview={logoImagesPreview}
                        onChangeLogoImageHandler={onChangeLogoImageHandler}
                        onDeleteImg={onDeleteImg}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-customer"
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateCollections
