import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Input,
  Label,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from '../../MyPagination'

//actions
import { getStarredOrders } from "store/orders/actions"

import "../../../assets/scss/datatables.scss"
import moment from "moment";

const StarredOrders = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

 
  //starred orders
  const { starredOrders, total, loading } = useSelector(state => ({
    starredOrders: state?.Orders.starredOrders?.orders,
    total: state?.Orders?.starredOrders?.total,
    loading: state?.Orders?.loading,
  }))

  //pagination 
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)


  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => { 
    dispatch(getStarredOrders(pageSend()))
  }, [dispatch, page, searchText])



  const columns = [

    {
      dataField: "_id",
      text: "Order ID",
      sort: true,
    },
    {
      dataField: "shopName",
      text: "Shop Name",
      sort: true,
    },
    {
      dataField: "createdAt", 
      text: "Order Date",
      sort: true,
    },
    {
      dataField: "shippingInfo.firstName",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "totalPrice",
      text: "Order Value",
      sort: true,
    },
    {
      dataField: "orderStatus",
      text: "Order Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const orderData = map(starredOrders, item => ({
    ...item,

    createdAt:(moment(item.createdAt).format("DD/MM/YYYY")),
    shopName:(
      (item.shop?.shopName)
    ),
    activeData:(
      <>
        {/* <Switch  onChange={() => handleSwitchChange(item?._id)} checked={item?.isActive} /> */}
        <div
          className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {handleSwitchChange(item?._id)}} checked={item?.isActive}
          />
          <label
            className="form-check-label"
            htmlFor="customSwitchsizemd"
          >
           {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action:(
      <div>
        <Link to={`/order/${item?._id}`} className="btn-light btn-sm">
          View Details
        </Link>
      </div>
    )

  }))

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: starredOrders?.length,
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const { SearchBar } = Search;

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };  

  const handleSwitchChange = (e,id) => {
    // TODO: API needed for status change
  }


  

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Col className="d-flex">
                <div className="col-3 me-5">
                  <Label>Search From</Label>
                  <Input type="date"  onChange={(e)=>
                    moment(e.target.value).format("DD/MM/YYYY")
                  }/>
                </div>
                <div className="col-3">
                  <Label>Search To</Label>
                  <Input type="date"
                  onChange={(e)=>
                    moment(e.target.value).format("DD/MM/YYYY")
                  }/>
                </div>
              </Col>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
                
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={orderData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      
                    </Row>
                    {loading ? (
                      <Spinner
                        color="secondary"
                        className="d-block m-auto"
                      />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                // keyField={"id"}
                                key={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={(item) => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          apiPage={pageSend}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default StarredOrders
