import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
  GET_SUBCATEGORIES, 
  CREATE_SUBCATEGORY,
  GET_SUBCATEGORY_DETAILS, 
  DELETE_SUBCATEGORY, 
  UPDATE_SUBCATEGORY } from "./actionTypes"
import {
  getSubCategoriesSuccess,
  getSubCategoriesFail,
  getSubCategoryDetailsSuccess,
  getSubCategoryDetailsFail,
  createSubCategoryFail,
  createSubCategorySuccess,
  updateSubCategorySuccess,
  updateSubCategoryFail,
  deleteSubCategorySuccess,
  deleteSubCategoryFail,
} from "./actions"

//Include Both Helper File with needed methods
// import { getCategories, getProjectsDetails, addNewProject, updateProject, deleteProject } from "helpers/fakebackend_helper"
import { post, get, ApiPut, del } from "../../helpers/api_helper"
import {Notification} from "../../components/Common/Notification"

function subCategoryApi() {
  return get("/subCategory/admin/all")
}

const getSubCategoryDetailsAPi = subCategoryId => {
  return get(`/subCategory/admin/${subCategoryId}`)
}

function createSubCategoryApi({subCategory}) {
  return post("/subCategory/admin/new", subCategory)
}

function updateSubCategoryApi({subCategory, subCategoryId}) {
  return ApiPut(`/subCategory/admin/${subCategoryId}`, subCategory)
}

function deleteSubCategoryApi(subCategoryId) {
  return del(`/subCategory/admin/${subCategoryId}`)
}



function* fetchSubCategories() {
  try {
    const response = yield call(subCategoryApi)
    yield put(getSubCategoriesSuccess(response))
  } catch (error) {
    yield put(getSubCategoriesFail(error))
  }
}

function* fetchSubCategoryDetails({ payload: subCategoryId }) {
  try {
    const response = yield call(getSubCategoryDetailsAPi, subCategoryId)
    yield put(getSubCategoryDetailsSuccess(response))
  } catch (error) {
    yield put(getSubCategoryDetailsFail(error))
  }
}

function* onCreateSubCategory({ payload }) {

  try {
    const response = yield call(createSubCategoryApi, payload)
    yield put(createSubCategorySuccess(response))
    Notification({
      type: "success",
      message: "SubCategory Created Successfully!",
      title: ""
    })
    payload.history.goBack();
  } catch (error) {

    yield put(createSubCategoryFail(error))
  }
}

function* onUpdateSubCategory({ payload}) {
  try {
    const response = yield call(updateSubCategoryApi, payload)
    yield put(updateSubCategorySuccess(response))
    Notification({
      type: "success",
      message: "SubCategory Updated Successfully!",
      title: ""
    })
    payload.history.goBack();
  } catch (error) {
    yield put(updateSubCategoryFail(error))
  }
}

function* onDeleteSubCategory({ subCategoryId, history }) {
  try {
    const response = yield call(deleteSubCategoryApi, subCategoryId)
    yield put(deleteSubCategorySuccess(response))
    Notification({
      type: "error",
      message: "SubCategory Deleted Successfully!",
      title: ""
    })
    history.goBack();
  } catch (error) {
    yield put(deleteSubCategoryFail(error))
  }
}



function* subCategorySaga() {
  yield takeEvery(GET_SUBCATEGORIES, fetchSubCategories)
  yield takeEvery(GET_SUBCATEGORY_DETAILS, fetchSubCategoryDetails)
  yield takeEvery(CREATE_SUBCATEGORY, onCreateSubCategory)
  yield takeEvery(UPDATE_SUBCATEGORY, onUpdateSubCategory)
  yield takeEvery(DELETE_SUBCATEGORY, onDeleteSubCategory)
}

export default subCategorySaga
