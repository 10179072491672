import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"
import moment from "moment"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from "../MyPagination"

//actions
import { getNotifications } from "store/notification/actions"

import "../../assets/scss/datatables.scss"

const Notifications = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const { notifications, total, loading } = useSelector(state => ({
    notifications: state.Notifications.notifications.notifications,
    total: state.Notifications.notifications.total,
    loading: state.Notifications.loading,
  }))

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getNotifications(pageSend()))
  }, [dispatch, page, searchText])

  const columns = [
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "user.name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
  ]

  const notificationData = map(notifications, (item, index) => ({
    ...item,
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),

    action: (
      <div>
        <Link to={`users/${item?._id}`}>
          {/* View Details */}
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        </Link>
        <Button
          id={`delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item?._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "20px" }}
          />
        </Button>

        <UncontrolledTooltip placement="bottom" target={`view${index}`}>
          View Details
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`delete${index}`}>
          Delete
        </UncontrolledTooltip>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: notifications?.length,
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList(
      notify.filter(notifi =>
        Object.keys(notifi).some(key =>
          notifi[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={notificationData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                // keyField={"id"}
                                key={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          apiPage={pageSend}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Notifications
