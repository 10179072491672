import axios from "axios"
import HandleError from "constants/handleError"

//apply base url for axios
const API_URL = "https://api.store.indtechhc.com"
// const API_URL_DEV = "http://127.0.0.1:4000"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token")

    if (token) {
      config.headers["Authorization"] =
        "Bearer " + localStorage.getItem("token")
    }
    return config
  },
  function (error) {
    return error
  }
)

// axiosApi.interceptors.response.use(
//   response => response,
//   error => HandleError(error.response)
// )
axiosApi.interceptors.response.use(response => response)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function ApiPut(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
