import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Card, CardBody, CardSubtitle, CardTitle, FormGroup } from "reactstrap"
import { Row, Col, Container } from "reactstrap"
import { TabContent, TabPane } from "reactstrap"
import Select from "react-select"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { getCollections, getGroupsDetails, updateGroup } from "store/actions"
import { map } from "lodash"
import ImageInput from "components/Common/MyInput"
import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

const UpdateGroups = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { groupDetails, loading, collection, Collections } = useSelector(
    state => ({
      loading: state.Group.loading,
      groupDetails: state.Group.groupDetails,
      collection: state.Group.groupDetails.collectionId,
      Collections: state.Collection.collections,
    })
  )

  const [bannerImages, setBannerImages] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])

  const [searchText, setSearchText] = useState("")
  const [selectedCollection, setSelectedCollection] = useState(
    "Search a Collection"
  )
  const [selectedCollectionId, setSelectedCollectionId] = useState("")

  const [activeTab, setActiveTab] = useState("1")

  const [layout, setLayout] = useState("")

  function handleValidSubmit(values) {
    const updateGroupData = {
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      priority: parseInt(values.priority),
      layout: layout,
      collectionId: selectedCollectionId,
      images: bannerImages,
    }
    console.log(updateGroupData)
    dispatch(updateGroup(updateGroupData, groupDetails?._id, history))
  }

  const onChangeImageHandler = e => {
    const files = Array.from(e.target.files)

    setBannerImagesPreview([])
    setBannerImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setBannerImagesPreview(oldArray => [
            ...oldArray,
            { url: reader.result, id: oldArray.length + 1 },
          ])
          setBannerImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  useEffect(() => {
    dispatch(getGroupsDetails(params?.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getCollections(1, "", searchText))
  }, [dispatch, searchText])

  useEffect(() => {
    setLayout(groupDetails?.layout)
    setBannerImages(groupDetails?.images)
  }, [groupDetails])

  const LayoutData = [
    {
      id: 1,
      title: "HIGHLIGHT",
      value: "highlight",
    },
    {
      id: 2,
      title: "LIST VERTICAL",
      value: "list_vertical",
    },
    {
      id: 3,
      title: "LIST HORIZONTAL",
      value: "list_horizontal",
    },
    {
      id: 4,
      title: " CAROUSEL",
      value: "carousel",
    },
    {
      id: 5,
      title: "LIST VERTICAL SMALL",
      value: "list_vertical_sm",
    },
  ]

  const BannerImage = groupDetails?.images

  const onDeleteImg = id => {
    setBannerImagesPreview(bannerImagesPreview?.filter(item => item.id !== id))
  }

  const isMult = LanguageSwitch()?.isMult

  //collection dropdown
  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  function handlerCollectionFinalValue(event) {
    setSelectedCollection(event.label)
    setSelectedCollectionId(event.value)
  }

  const collectionOptions = [
    {
      options:
        Collections?.length &&
        Collections?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.title,
          value: result._id,
        })),
    },
  ]

  useEffect(() => {
    setSelectedCollection(collection?.primaryLang?.title)
    setSelectedCollectionId(collection?._id)
  }, [collection])

  return (
    <>
      <MetaTag title={"Update Groups"} />

      <div className="page-content">
        <Breadcrumbs title="Classifications" breadcrumbItem="Update Groups" />
        <Container fluid>
          <div className="container-fluid mb-5">
            <Row>
              <Col lg={12} md={12}>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(v)
                  }}
                >
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xl="4" lg="4" md="6" sm="12">
                            <CardTitle
                              className="h4 mb-4"
                              style={{ color: "#1e81b0" }}
                            >
                              Priority
                            </CardTitle>
                            <div className="mb-3">
                              <AvField
                                name="priority"
                                value={groupDetails?.priority}
                                label=""
                                type="number"
                              />
                            </div>
                          </Col>

                          <Col xl="4" lg="4" md="6" sm="12">
                            <CardTitle
                              className="h4 mb-4 "
                              style={{ color: "#1e81b0" }}
                            >
                              Collections
                            </CardTitle>
                            <FormGroup className="mb-3">
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleEnters}
                                  value={selectedCollection}
                                  placeholder={selectedCollection}
                                  onChange={handlerCollectionFinalValue}
                                  options={collectionOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={true}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <CardTitle
                          className="h4 mb-4"
                          style={{ color: "#1e81b0" }}
                        >
                          Layout
                        </CardTitle>
                        <Row>
                          <div
                            className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                            role="group"
                            aria-label="Basic radio toggle button group"
                          >
                            {map(LayoutData, (item, key) => (
                              <div key={key} className="me-3">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id={item.id}
                                  autoComplete="off"
                                  value={item.value}
                                  onChange={e => setLayout(e.target.value)}
                                  checked={layout === item.value}
                                />
                                <label
                                  className="btn btn-outline-info"
                                  htmlFor={item.id}
                                >
                                  {item.title}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="12">
                    <Card>
                      <CardBody>
                        {isMult && (
                          <LanguageTab
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                          />
                        )}

                        <TabContent activeTab={activeTab} className="">
                          <TabPane tabId="1">
                            <Row>
                              <Col className="col-12 d-flex flex-wrap">
                                <Col className="col-12 d-flex flex-wrap">
                                  <div className="col-12 mb-3">
                                    <AvField
                                      name="primaryLang.title"
                                      label="Title"
                                      type="text"
                                      errorMessage="Invalid product name"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      value={
                                        groupDetails?.primaryLang?.title || ""
                                      }
                                    />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <AvField
                                      name="primaryLang.description"
                                      label="Description"
                                      type="textarea"
                                      errorMessage="Invalid description"
                                      rows="7"
                                      value={
                                        groupDetails?.primaryLang
                                          ?.description || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane tabId="2">
                            <Row>
                              <Col xl="12">
                                <Row>
                                  <Col className="col-12 d-flex flex-wrap">
                                    <Col className="col-12 d-flex flex-wrap">
                                      <div className="col-12 mb-3">
                                        <div className="d-flex justify-content-between mb-2">
                                          <CardSubtitle>Name</CardSubtitle>
                                          <CardSubtitle>عنوان</CardSubtitle>
                                        </div>
                                        <AvField
                                          style={{ textAlign: "right" }}
                                          name="secondaryLang.title"
                                          label=""
                                          type="text"
                                          value={
                                            groupDetails?.secondaryLang
                                              ?.title || ""
                                          }
                                        />
                                      </div>
                                      <div className="col-12 mb-3">
                                        <div className="d-flex justify-content-between mb-2">
                                          <CardSubtitle>
                                            Description
                                          </CardSubtitle>
                                          <CardSubtitle>وصف</CardSubtitle>
                                        </div>
                                        <AvField
                                          style={{ textAlign: "right" }}
                                          name="secondaryLang.description"
                                          label=""
                                          type="textarea"
                                          rows="7"
                                          value={
                                            groupDetails?.secondaryLang
                                              ?.description || ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <CardTitle
                          className="h4 mb-2 "
                          style={{ color: "#1e81b0" }}
                        >
                          Products
                        </CardTitle>

                        {map(collection?.products, (item, key) => (
                          <div key={key} className="mb-2">
                            <div className="d-flex align-items-center">
                              <img
                                className="img-thumbnail rounded-circle "
                                style={{ height: "60px", width: "60px" }}
                                src={item?.images[0]?.url}
                                alt="product"
                              />
                              <p className="pb-0 mb-0 px-3">
                                {item?.primaryLang.name}
                              </p>
                              <i
                                className="bx bx-trash-alt "
                                style={{
                                  fontSize: "1rem",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveOldproduct(item._id)}
                              ></i>
                            </div>
                          </div>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>

                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <div className="form-group px-3">
                            <CardTitle className="mb-4">
                              Banner images :
                            </CardTitle>
                            {BannerImage?.length >= 1 && (
                              <Row className="mb-4">
                                <Col className="d-flex flex-wrap">
                                  {BannerImage &&
                                    BannerImage.map((img, key) => (
                                      <img
                                        key={key}
                                        src={img?.url}
                                        alt={img.url}
                                        className="mb-4"
                                        style={{
                                          marginRight: "1rem",
                                          height: "100px",
                                          width: "100px",
                                        }}
                                        width="100"
                                        height="100"
                                      />
                                    ))}
                                </Col>
                              </Row>
                            )}

                            <div className="custom-file d-flex justify-content-between align-items-center">
                              <input
                                type="file"
                                name="banner_images"
                                className="custom-file-input"
                                id="banner_input"
                                onChange={onChangeImageHandler}
                                multiple
                                hidden
                              />
                              <ImageInput htmlFor="banner_input" />

                              <div className="d-flex justify-content-end flex-wrap align-items-center">
                                {bannerImagesPreview?.map((img, index) => (
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                    className="mb-2"
                                    key={index}
                                  >
                                    <img
                                      src={img?.url}
                                      alt="Images Preview"
                                      className="mr-2"
                                      style={{ marginRight: ".5rem" }}
                                      width="75"
                                      height="72"
                                    />
                                    <i
                                      className="bx bx-x-circle"
                                      style={{
                                        color: "red",
                                        fontSize: "1.2rem",
                                        position: "absolute",
                                        top: "2px",
                                        right: "12px",
                                        background: "#e9e9ea",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => onDeleteImg(img?.id)}
                                    ></i>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-customer"
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateGroups
