import React, { useState } from "react"
import { Row, Col, Spinner, Button } from "reactstrap"
import { useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"
import noImage from "../../../assets/images/zeston/noimage3.png"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from "../../MyPagination"

import "../../../assets/scss/datatables.scss"
import { Link } from "react-router-dom"
import { LanguageSwitch } from "common/LanguageSwitch"
import { CurrencySwitch } from "common/Currency"

const FavDatatable = () => {
  const [page, setPage] = useState(1)

  const { userFavouriteDetails, loading } = useSelector(state => ({
    userFavouriteDetails: state.Users.userFavouriteDetails,
    loading: state.Users.loading,
  }))

  const total = userFavouriteDetails?.length

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const PreColumns = [
    {
      dataField: "imageData",
      text: "Image",
    },
    {
      dataField: "productName",
      text: "Name",
    },
    {
      dataField: "product.secondaryLang.name",
      text: "Secondary Name",
    },
    {
      dataField: "sellingPrice",
      text: "Price",
      sort: true,
    },

    // {
    //   dataField: "action",
    //   text: "view",
    // },
  ]

  const columns = LanguageSwitch()?.isMult
    ? PreColumns
    : PreColumns?.filter(i => i.text !== "Secondary Name")

  const currency = CurrencySwitch()

  const userFavData = map(userFavouriteDetails, (item, index) => ({
    ...item,
    productName: (
      <Link to={`/product/${item?.product?._id}`}>
        {item?.product?.primaryLang?.name}
      </Link>
    ),
    imageData: (
      <img
        className="rounded-circle header-profile-user"
        style={{ width: "70px", height: "70px" }}
        src={item?.product?.images ? item?.product?.images[0]?.url : noImage}
      />
    ),
    sellingPrice: (
      <span>
        {currency}&nbsp;
        {item?.product?.sellingPrice}{" "}
      </span>
    ),
    action: (
      <Link to={`product/${item?._id}`}>
        <Button
          id={`view${index}`}
          type="button"
          color="white"
          className="ms-1 btn"
        >
          <i
            className="bx bx-show-alt me-2"
            style={{ color: "green", fontSize: "22px" }}
          />
        </Button>
      </Link>
    ),
  }))
  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={userFavData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // keyField={"id"}
                            key={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            // defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <MyPagination
                      pages={pages}
                      clcickedPage={page}
                      onNunClick={item => setPage(item)}
                      onNextClick={() => setPage(page + 1)}
                      onPrevClick={() => setPage(page - 1)}
                      apiPage={pageSend}
                    />
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FavDatatable
