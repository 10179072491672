import MetaTag from "components/Common/Meta-tag"
import React from "react"

import Settingsd from "./Details"

function BannerDetails() {
  return (
    <>
      <MetaTag title={"Settings"} />

      <Settingsd />
    </>
  )
}

export default BannerDetails
