import {
  GET_AREA_LISTS,
  GET_AREA_LISTS_FAIL,
  GET_AREA_LISTS_SUCCESS,
  GET_AREA_LIST_DETAIL,
  GET_AREA_LIST_DETAIL_SUCCESS,
  GET_AREA_LIST_DETAIL_FAIL,
  CREATE_AREA_LIST_SUCCESS,
  CREATE_AREA_LIST_FAIL,
  UPDATE_AREA_LIST,
  UPDATE_AREA_LIST_SUCCESS,
  UPDATE_AREA_LIST_FAIL,
  DELETE_AREA_LIST_SUCCESS,
  DELETE_AREA_LIST_FAIL,
  CREATE_AREA_LIST,
} from "./actionTypes"

const INIT_STATE = {
  areas: [],
  areaDetails: {},
  error: {},
  loading: false,
}

const Area = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AREA_LISTS:
    case GET_AREA_LIST_DETAIL:
    case UPDATE_AREA_LIST:
    case CREATE_AREA_LIST:
      return {
        ...state,
        loading: true,
      }
    case GET_AREA_LISTS_SUCCESS:
      return {
        ...state,
        areas: action.payload,
        loading: false,
      }

    case GET_AREA_LISTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_AREA_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        areaDetails: action.payload,
        loading: false,
      }

    case GET_AREA_LIST_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_AREA_LIST_SUCCESS:
      return {
        ...state,
        areas: [...state.areas, action.payload],
        loading: false,
      }

    case CREATE_AREA_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_AREA_LIST_SUCCESS:
      return {
        ...state,
        areas: [...state.areas].map(area =>
          area._id.toString() === action.payload._id.toString()
            ? { area, ...action.payload }
            : area
        ),
        loading: false,
      }

    case UPDATE_AREA_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_AREA_LIST_SUCCESS:
      return {
        ...state,
        areas: state.areas.filter(
          area => area._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
      }

    case DELETE_AREA_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Area
