import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERS,
  GET_USER_DETAILS,
  UPDATE_USER,
  DELETE_USER,
  GET_USER_CARTS,
  GET_USER_CART_DETAILS,
  DELETE_USER_CART,
  GET_USER_FAVOURITE_DETAILS,
  DELETE_USER_FAVOURITE,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserDetailsSuccess,
  getUserDetailsFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getUserCartsSuccess,
  getUserCartsFail,
  getUserCartDetailsSuccess,
  getUserCartDetailsFail,
  deleteUserCartSuccess,
  deleteUserCartFail,
  getUserFavouriteDetailsSuccess,
  getUserFavouriteDetailsFail,
  deleteUserFavouriteSuccess,
  deleteUserFavouriteFail,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// function getZestonProductsAPi({ searchText, page }) {
//   if (searchText) {
//     return get(`/zestonProduct/all?search=${searchText && searchText}`)
//   } else {
//     return get(`/zestonProduct/all?page=${page ? page : 1}&limit=10`)
//   }
// }

function getUsersAPi({ page }) {
  return get(`/user/admin/all?page=${page ? page : 1}&limit=10`)
}

function getCartsAPi() {
  return get("/cart/admin/all")
}

const getUserDetailsAPi = userId => {
  return get(`/user/admin/${userId}`)
}

const updateUserApi = ({ user, userId }) => {
  return ApiPut(`/users/admin/${userId}`, user)
}

const deleteUserApi = ({ userId }) => {
  return del(`/user/admin/${userId}`), del(`/cart/admin/${userId}`)
}

const getUserCartDetailsAPi = userId => {
  return get(`/cart/admin/user/${userId}`)
}

const getUserFavouriteDetailsAPi = userId => {
  return get(`/favourite/admin/userId/${userId}`)
}

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsersAPi, payload)
    // const cresponse = yield call(getCartsAPi) //payload
    yield put(getUsersSuccess(response))
    // yield put(getUserCartsSuccess(cresponse))
  } catch (error) {
    yield put(getUsersFail(error))
    yield put(getUserCartsFail(error))
  }
}

function* fetchUserDetail({ payload: userId }) {
  try {
    const response = yield call(getUserDetailsAPi, userId)
    yield put(getUserDetailsSuccess(response))
  } catch (error) {
    yield put(getUserDetailsFail(error))
  }
}

function* fetchUserCartDetail({ payload: userId }) {
  try {
    const response = yield call(getUserCartDetailsAPi, userId)
    yield put(getUserCartDetailsSuccess(response))
  } catch (error) {
    yield put(getUserCartDetailsFail(error))
  }
}

function* fetchUserFavouriteDetail({ payload: userId }) {
  try {
    const response = yield call(getUserFavouriteDetailsAPi, userId)
    yield put(getUserFavouriteDetailsSuccess(response))
  } catch (error) {
    yield put(getUserFavouriteDetailsFail(error))
  }
}

function* onUpdateUser({ payload }) {
  try {
    const response = yield call(updateUserApi, payload)
    yield put(updateUserSuccess(response))
    Notification({
      type: "success",
      message: "User Updated Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ userId, history }) {
  try {
    const response = yield call(deleteUserApi, userId)
    yield put(deleteUserSuccess(response))
    yield put(deleteUserCartSuccess(response))
    Notification({
      type: "error",
      message: "User Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    yield put(deleteUserFail(error))
    yield put(deleteUserCartFail(error))
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_CARTS, fetchUsers)
  yield takeEvery(GET_USER_DETAILS, fetchUserDetail)
  yield takeEvery(GET_USER_CART_DETAILS, fetchUserCartDetail)
  yield takeEvery(GET_USER_FAVOURITE_DETAILS, fetchUserFavouriteDetail)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(DELETE_USER_CART, onDeleteUser)
}

export default userSaga
