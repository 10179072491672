/* PROJECTS */
export const GET_GROUPS = "GET_GROUPS"
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS"
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL"

export const GET_GROUP_DETAILS = "GET_GROUP_DETAILS"
export const GET_GROUP_DETAILS_SUCCESS = "GET_GROUP_DETAILS_SUCCESS"
export const GET_GROUP_DETAILS_FAIL = "GET_GROUP_DETAILS_FAIL"

/**
 * add user
 */
export const CREATE_GROUP = "CREATE_GROUP"
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS"
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL"

/**
 * Edit user
 */
export const UPDATE_GROUP = "UPDATE_GROUP"
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS"
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL"

/**
 * Delete user
 */
export const DELETE_GROUP = "DELETE_GROUP"
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL"
