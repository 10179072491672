import {
  GET_GROUPS,
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GET_GROUP_DETAILS,
  GET_GROUP_DETAILS_SUCCESS,
  GET_GROUP_DETAILS_FAIL,
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
} from "./actionTypes"

//all groups
export const getGroups = ({ page, limit, searchText }) => ({
  type: GET_GROUPS,
  payload: { page, limit, searchText },
})

//all Groups
export const getGroupsSuccess = groups => ({
  type: GET_GROUPS_SUCCESS,
  payload: groups,
})

export const getGroupsFail = error => ({
  type: GET_GROUPS_FAIL,
  payload: error,
})

export const getGroupsDetails = groupId => ({
  type: GET_GROUP_DETAILS,
  payload: groupId,
})

export const getGroupsDtailsSuccess = groupDetails => ({
  type: GET_GROUP_DETAILS_SUCCESS,
  payload: groupDetails,
})

export const getGroupsDtailsFail = error => ({
  type: GET_GROUP_DETAILS_FAIL,
  payload: error,
})

export const createGroup = (group, history) => ({
  type: CREATE_GROUP,
  payload: { group, history },
})

export const createGroupSuccess = group => ({
  type: CREATE_GROUP_SUCCESS,
  payload: group,
})

export const createGroupFail = error => ({
  type: CREATE_GROUP_FAIL,
  payload: error,
})

export const updateGroup = (group, groupId, history) => ({
  type: UPDATE_GROUP,
  payload: { group, groupId, history },
})

export const updateGroupSuccess = group => ({
  type: UPDATE_GROUP_SUCCESS,
  payload: group,
})

export const updateGroupFail = error => ({
  type: UPDATE_GROUP_FAIL,
  payload: error,
})

export const deleteGroup = (groupId, history) => ({
  type: DELETE_GROUP,
  groupId,
  history,
})

export const deleteGroupSuccess = group => ({
  type: DELETE_GROUP_SUCCESS,
  payload: group,
})

export const deleteGroupFail = error => ({
  type: DELETE_GROUP_FAIL,
  payload: error,
})
