import React from 'react'

import RevenueDetails from './Details'


function RevenueDetail() {
    return (
        <>
            
                <RevenueDetails />
                    
            
        </>
    )
}

export default RevenueDetail
