import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import makeAnimated from "react-select/animated"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//actions
import { createShopProduct } from "store/actions"
// import { getZestonProducts, getZestonProductDetails } from "store/Products/actions"
import { getShops } from "store/shops/actions"
import MetaTag from "components/Common/Meta-tag"

const CreateShopProduct = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const animatedComponents = makeAnimated()
  const [activeTab, setActiveTab] = useState(0)

  const { loading, createShopProductError } = useSelector(state => ({
    createShopProductError: state.ShopProducts.createShopProductError,
    loading: state.ShopProducts.loading,
  }))

  const { zestonProducts, zestonProductLoading } = useSelector(state => ({
    zestonProducts: state.ZestonProducts.zestonProducts,
    zestonProductLoading: state.ZestonProducts.loading,
  }))

  const { zestonProductDetails, zestonProductDetailsLoading } = useSelector(
    state => ({
      zestonProductDetails: state.ZestonProducts.zestonProductDetails,
      productVarients: state.ZestonProducts.productVarients,
      zestonProductDetailsLoading: state.ZestonProducts.loading,
    })
  )

  const { shops, shopLoading } = useSelector(state => ({
    shops: state.Shops.shops,
    shopLoading: state.Shops.loading,
  }))

  const [isFeatured, setIsFeatured] = useState()
  const [imgRows, setImgRows] = useState([{ id: 1 }])
  const [sizes, setSizes] = useState([])
  const [shopSizes, setShopSizes] = useState([])
  const [specs, setSpecs] = useState([])

  const [zestonProductId, setZestonProductId] = useState("")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const imageShow = (img, id) => {
    const expandImg = {}
    expandImg.src = document.getElementById("expandedImg")
  }

  const onAddImgRow = () => {
    const modifiedRows = [...imgRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setImgRows(modifiedRows)
  }

  const onDeleteImgRow = id => {
    if (id !== 1) {
      var modifiedRows = [...imgRows]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setImgRows(modifiedRows)
    }
  }

  function handleValidSubmit(values) {
    const createProduct = {
      shop: values.shop,
      zestonProduct: values.zestonProduct,
      customID: values.customID,
      sizes: shopSizes,
      isFeatured: values.isFeatured,
    }

    dispatch(createShopProduct(createProduct, history))
  }

  const handleGetDetails = async id => {
    await dispatch(getZestonProductDetails(id))
    await handleGetShopSizes()
    setZestonProductId(id)
  }

  const handleGetShopSizes = async () => {
    const shopSizes = []
    await zestonProductDetails?.sizes?.forEach(function (zestonSize) {
      const obj = new Object()
      obj.value = zestonSize.value
      obj.zestonPrice = zestonSize.price
      obj.retailPrice = 0
      obj.stock = 0
      shopSizes.push(obj)
    })
    setShopSizes(shopSizes)
  }

  useEffect(() => {
    dispatch(getZestonProducts())
  }, [dispatch])

  useEffect(() => {
    dispatch(getShops())
  }, [dispatch])

  return (
    <>
      <MetaTag title="Create Shop Product" />

      <div className="page-content">
        <Breadcrumbs
          title="Shop Products"
          breadcrumbItem="Create Shop Product"
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12} md={12}>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(v)
                  }}
                >
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row className="col-12 mb-3 mx-3">
                        <Col md="2">
                          <label> Shop :</label>
                        </Col>
                        <Col md="6">
                          <AvField
                            type="select"
                            name="shop"
                            errorMessage="Select a Dealer Shop"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={e => {
                              handleGetDetails(e.target.value)
                            }}
                          >
                            <option>Select a Dealer Shop</option>
                            {shops?.shops?.map((item, key) => (
                              <option key={key} value={item._id}>
                                {item.shopName}
                              </option>
                            ))}
                          </AvField>
                        </Col>
                      </Row>
                      <Row className="col-12 mb-3 mx-3">
                        <Col md="2">
                          <label> Zeston Product :</label>
                        </Col>
                        <Col md="6">
                          <AvField
                            type="select"
                            name="zestonProduct"
                            errorMessage="Select a Zeston Product"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={async e => {
                              await handleGetDetails(e.target.value)
                            }}
                          >
                            <option>Select a Zeston Product</option>
                            {zestonProducts?.map((item, key) => (
                              <option key={key} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </AvField>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  {/* Zeston Product Details */}
                  {zestonProductDetails?.name && (
                    <Card>
                      <CardBody>
                        {zestonProductDetailsLoading ? (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        ) : (
                          <>
                            <Row>
                              <label
                                className="mx-3 mb-3"
                                style={{ fontWeight: "bold" }}
                              >
                                {" "}
                                Zeston Product Details :
                              </label>
                              <Col xl="6">
                                <div className="product-detai-imgs">
                                  <Row>
                                    <Col md="2" xs="3">
                                      <Nav className="flex-column" pills>
                                        {zestonProductDetails?.images?.map(
                                          (image, index) => (
                                            <NavItem key={index}>
                                              <NavLink
                                                className={classnames({
                                                  active:
                                                    activeTab === { index },
                                                })}
                                                onClick={() => {
                                                  toggleTab(index)
                                                }}
                                              >
                                                <img
                                                  src={image.url}
                                                  alt=""
                                                  onClick={() => {
                                                    imageShow(image.url, index)
                                                  }}
                                                  className="img-fluid mx-auto d-block rounded"
                                                />
                                              </NavLink>
                                            </NavItem>
                                          )
                                        )}
                                      </Nav>
                                    </Col>
                                    <Col md={{ size: 7, offset: 1 }} xs="9">
                                      <TabContent activeTab={activeTab}>
                                        {zestonProductDetails?.images?.map(
                                          (image, index) => (
                                            <TabPane key={index} tabId={index}>
                                              <div>
                                                <img
                                                  src={image.url}
                                                  alt=""
                                                  id="expandedImg1"
                                                  className="img-fluid mx-auto d-block"
                                                />
                                              </div>
                                            </TabPane>
                                          )
                                        )}
                                      </TabContent>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div className="mt-4 mt-xl-3">
                                  <Link to="#" className="text-primary">
                                    {zestonProductDetails?.category?.name}
                                  </Link>
                                  <h4 className="mt-1 mb-3">
                                    {zestonProductDetails.name}
                                  </h4>
                                  <p className="text-muted mb-4">
                                    {zestonProductDetails?.description}
                                  </p>

                                  <Row className="product-color">
                                    <Col md="3">
                                      <h5 className="font-size-15">Color :</h5>
                                    </Col>
                                    <Col md="4">
                                      <Row
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Col
                                          md="2"
                                          className={
                                            "avatar-title rounded-circle bg-soft"
                                          }
                                          style={{
                                            backgroundColor: `${zestonProductDetails?.color?.value}`,
                                            width: "30px",
                                            height: "30px",
                                            display:
                                              zestonProductDetails?.color
                                                ?.value == "Not Specified"
                                                ? "none"
                                                : "",
                                          }}
                                        ></Col>
                                        <Col>
                                          {zestonProductDetails?.color?.name}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>

                            <div className="mt-5">
                              <h5 className="mb-3">Specifications :</h5>
                              <Col md="6" className="mx-3">
                                {zestonProductDetails.specs &&
                                  zestonProductDetails.specs.map((item, i) => (
                                    <div key={i}>
                                      <Row>
                                        <Col md="3">
                                          <i
                                            className={classnames(
                                              " fa fa-caret-right font-size-16 align-middle text-primary me-2"
                                            )}
                                          />
                                          {item.title && `${item.title}`}
                                        </Col>
                                        <Col md="1">{`:`}</Col>
                                        <Col md="3">
                                          {item.details && `${item.details}`}
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                              </Col>
                            </div>

                            <div className="mt-4">
                              <h5 className="mb-3">Sizes :</h5>
                              <Col md="6" className="mx-3">
                                {zestonProductDetails?.sizes &&
                                  zestonProductDetails?.sizes?.map(
                                    (item, i) => (
                                      <div key={i}>
                                        <Row>
                                          <Col md="3">
                                            <i
                                              className={classnames(
                                                " fa fa-caret-right font-size-16 align-middle text-primary me-2"
                                              )}
                                            />
                                            {item.value && `${item.value}`}
                                          </Col>
                                          <Col md="1">{`:`}</Col>
                                          <Col md="3">
                                            {item.price && `₹ ${item.price}`}
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                  )}
                              </Col>
                            </div>
                          </>
                        )}
                      </CardBody>
                    </Card>
                  )}

                  {zestonProductDetails && zestonProductDetails?.name && (
                    <Card>
                      <CardBody>
                        <CardTitle className="h4 mb-4"></CardTitle>

                        <Col>
                          <div className="col-2 mb-2 mx-3">
                            <AvField
                              name="customID"
                              label="Custom ID"
                              type="text"
                              // errorMessage="Invalid product ID"
                              // validate={{
                              //   required: { value: true },
                              // }}
                              value=""
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="col-3 mt-4 mb-4 mx-3">
                            <AvGroup check>
                              <AvInput type="checkbox" name="isFeatured" />
                              <Label check for="checkbox">
                                {" "}
                                Featured Product
                              </Label>
                            </AvGroup>
                          </div>
                        </Col>
                        <Row>
                          <Col className="col-12 d-flex flex-wrap">
                            {/*Shop sizes */}
                            <div className="col-12 mb-3 px-3">
                              <Row>
                                {/* <Col md="2"> 
                                <Label htmlFor="lbltype" className="form-label">Shop Sizes :</Label>
                              </Col> */}
                                <Col md="4">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="ms-1 btn mt-2 mb-4"
                                    onClick={() => {
                                      handleGetShopSizes()
                                    }}
                                  >
                                    {/* <i className="bx bx-trash me-2" /> */}
                                    Get Shop Sizes
                                  </Button>
                                </Col>
                              </Row>

                              {shopSizes && shopSizes?.length > 0 && (
                                <Col md="12" className="mx-5 mb-3">
                                  <Row>
                                    <Col
                                      style={{
                                        color: "purple",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Size
                                    </Col>
                                    <Col
                                      style={{
                                        color: "purple",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Zeston Price
                                    </Col>
                                    <Col
                                      style={{
                                        color: "purple",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Retail Price
                                    </Col>
                                    <Col
                                      style={{
                                        color: "purple",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Stock
                                    </Col>
                                    <Col
                                      style={{
                                        color: "purple",
                                        fontWeight: "bold",
                                      }}
                                    ></Col>
                                  </Row>
                                </Col>
                              )}

                              <Col md="12" className="mx-3">
                                {shopSizes?.map((s, index) => {
                                  return (
                                    <div key={index}>
                                      <Row>
                                        <Col>
                                          <AvField
                                            readOnly
                                            name="value"
                                            type="text"
                                            value={s.value}
                                            placeholder="Value"
                                            onChange={e => {
                                              const value = e.target.value
                                              setShopSizes(currentSize =>
                                                currentSize.map(x =>
                                                  x === s ? { ...x, value } : x
                                                )
                                              )
                                            }}
                                          />
                                        </Col>

                                        <Col className="db__column">
                                          <AvField
                                            readOnly
                                            name="zestonPrice"
                                            type="text"
                                            value={s.zestonPrice}
                                            placeholder="Zeston Price"
                                            onChange={e => {
                                              const zestonPrice = e.target.value
                                              setShopSizes(currentSize =>
                                                currentSize.map(x =>
                                                  x === s
                                                    ? { ...x, zestonPrice }
                                                    : x
                                                )
                                              )
                                            }}
                                          />
                                        </Col>

                                        <Col className="db__column">
                                          <AvField
                                            name="retailPrice"
                                            type="text"
                                            value={s.retailPrice}
                                            placeholder="Retail Price"
                                            onChange={e => {
                                              const retailPrice = e.target.value
                                              setShopSizes(currentSize =>
                                                currentSize.map(x =>
                                                  x === s
                                                    ? { ...x, retailPrice }
                                                    : x
                                                )
                                              )
                                            }}
                                          />
                                        </Col>

                                        <Col className="db__column">
                                          <AvField
                                            name="stock"
                                            type="text"
                                            value={s.stock}
                                            placeholder="Stock"
                                            onChange={e => {
                                              const stock = e.target.value
                                              setShopSizes(currentSize =>
                                                currentSize.map(x =>
                                                  x === s ? { ...x, stock } : x
                                                )
                                              )
                                            }}
                                          />
                                        </Col>

                                        <Col>
                                          <button
                                            className="mx-2 btn"
                                            onClick={e => {
                                              e.preventDefault()
                                              setShopSizes(currentSpec =>
                                                currentSpec.filter(x => x !== s)
                                              )
                                            }}
                                          >
                                            <i
                                              className="bx bx-trash"
                                              style={{ fontSize: "20px" }}
                                            ></i>
                                          </button>
                                        </Col>
                                      </Row>
                                    </div>
                                  )
                                })}
                              </Col>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-customer"
                              >
                                {loading && (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                  </>
                                )}
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </AvForm>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateShopProduct
