export const GET_GENDER_TYPES = "GET_GENDER_TYPES"
export const GET_GENDER_TYPES_SUCCESS = "GET_GENDER_TYPES_SUCCESS"
export const GET_GENDER_TYPES_FAIL = "GET_GENDER_TYPES_FAIL"

export const GET_GENDER_TYPE_DETAILS = "GET_GENDER_TYPE_DETAILS"
export const GET_GENDER_TYPE_DETAILS_SUCCESS = "GET_GENDER_TYPE_DETAILS_SUCCESS"
export const GET_GENDER_TYPE_DETAILS_FAIL = "GET_GENDER_TYPE_DETAILS_FAIL"

export const CREATE_GENDER_TYPE = "CREATE_GENDER_TYPE"
export const CREATE_GENDER_TYPE_SUCCESS = "CREATE_GENDER_TYPE_SUCCESS"
export const CREATE_GENDER_TYPE_FAIL = "CREATE_GENDER_TYPE_FAIL"

export const UPDATE_GENDER_TYPE = "UPDATE_GENDER_TYPE"
export const UPDATE_GENDER_TYPE_SUCCESS = "UPDATE_GENDER_TYPE_SUCCESS"
export const UPDATE_GENDER_TYPE_FAIL = "UPDATE_GENDER_TYPE_FAIL"

export const DELETE_GENDER_TYPE = "DELETE_GENDER_TYPE"
export const DELETE_GENDER_TYPE_SUCCESS = "DELETE_GENDER_TYPE_SUCCESS"
export const DELETE_GENDER_TYPE_FAIL = "DELETE_GENDER_TYPE_FAIL"
